import React, { useState, useEffect } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as needed
import {
  FaHome,
  FaChevronRight,
  FaCopy,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaPrint,
  FaChevronLeft,
  FaChevronRight as FaChevronRightIcon,
} from 'react-icons/fa'; // Import React Icons
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const origin = window.location.origin; // Get the origin domain dynamically

const API_URL = `${origin}/api/get_history.php`; // Replace with the correct URL

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const [data, setData] = useState([]); // State to store fetched history data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // Function to fetch history data
  const fetchHistoryData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    if (!token) {
      setError('Authentication token not found');
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('token', token); // Send token in form data

      const response = await fetch(API_URL, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        // Sort data in descending order based on 'startTime'
        const sortedData = result.data.sort((a, b) => {
          // Ensure 'startTime' exists and is a valid date
          const dateA = new Date(a.startTime);
          const dateB = new Date(b.startTime);
          return dateB - dateA; // For newest first
        });

        setData(sortedData);
        setLoading(false);
      } else {
        setError(result.message || 'Failed to fetch history data.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching history data:', error);
      setError('An error occurred while fetching history data.');
      setLoading(false);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchHistoryData();
  }, []); // Empty dependency array ensures this runs once

  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  // Handle search functionality
  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
  );

  // Get current entries based on page and filtered data
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = filteredData.slice(startIndex, startIndex + entriesPerPage);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === 'next') {
        return Math.min(prevPage + 1, Math.ceil(filteredData.length / entriesPerPage));
      } else if (direction === 'prev') {
        return Math.max(prevPage - 1, 1);
      }
      return prevPage;
    });
  };

  // Function to format numbers by removing decimals
  const formatNumber = (num) => {
    if (num === null || num === undefined) return '-';
    const parsed = parseFloat(num);
    if (isNaN(parsed)) return '-';
    return Math.round(parsed).toLocaleString();
  };

  // Function to determine status color
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'processing':
        return 'text-orange-500';
      case 'lose':
        return 'text-red-500';
      case 'win':
        return 'text-green-500';
      default:
        return 'text-white';
    }
  };

  // Function to determine type color
  const getTypeColor = (type) => {
    switch (type.toLowerCase()) {
      case 'buy':
        return 'text-green-500';
      case 'sell':
        return 'text-red-500';
      default:
        return 'text-white';
    }
  };

  return (
    <div className="bg-[#1F1F1F] min-h-screen w-full text-white p-5 box-border overflow-x-hidden">
      <div className="flex flex-col h-full">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">History</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>History</span>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-grow flex flex-col">
          <Ticker />

          {/* Scrollable Container */}
          <div className="flex-grow overflow-auto bg-[#1F1F1F] border border-gray-600 rounded-lg p-4 mt-4">
            {/* Control and Table Container */}
            <div className="flex flex-col">
              {/* Table Header */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
                {/* Search Input */}
                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1); // Reset to first page on search
                    }}
                    className="p-2 rounded bg-[#252525] text-white w-full sm:w-auto outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                  />
                </div>

                {/* Export Buttons (Disabled) */}
                <div className="flex space-x-2 mt-2 sm:mt-0 ml-auto">
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaCopy />
                    <span className="hidden sm:inline">Copy</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFileCsv />
                    <span className="hidden sm:inline">CSV</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFileExcel />
                    <span className="hidden sm:inline">Excel</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFilePdf />
                    <span className="hidden sm:inline">PDF</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaPrint />
                    <span className="hidden sm:inline">Print</span>
                  </button>
                </div>
              </div>

              {/* Table */}
              <div className="overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center p-4">
                    <svg
                      className="animate-spin h-8 w-8 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  </div>
                ) : error ? (
                  <div className="text-center text-red-400 p-4">{error}</div>
                ) : filteredData.length > 0 ? (
                  <table className="min-w-full bg-[#1F1F1F] border-collapse border border-gray-600">
                    <thead>
                      <tr>
                        <th className="p-2 border border-gray-600 text-center">Order ID</th>
                        <th className="p-2 border border-gray-600 text-center">Type</th>
                        <th className="p-2 border border-gray-600 text-center">Amount</th>
                        <th className="p-2 border border-gray-600 text-center">Pair</th>
                        <th className="p-2 border border-gray-600 text-center">Start Price</th>
                        <th className="p-2 border border-gray-600 text-center">Last Price</th>
                        <th className="p-2 border border-gray-600 text-center">Status</th>
                        <th className="p-2 border border-gray-600 text-center">Start Time</th>
                        <th className="p-2 border border-gray-600 text-center">End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEntries.map((row, index) => (
                        <tr key={index}>
                          <td className="p-2 border border-gray-600 text-center">{row.orderId || '-'}</td>
                          <td
                            className={`p-2 border border-gray-600 text-center ${getTypeColor(row.type)}`}
                          >
                            {row.type || '-'}
                          </td>
                          <td className="p-2 border border-gray-600 text-center">{formatNumber(row.amount)}</td>
                          <td className="p-2 border border-gray-600 text-center">{row.pair || '-'}</td>
                          <td className="p-2 border border-gray-600 text-center">{formatNumber(row.startPrice)}</td>
                          <td className="p-2 border border-gray-600 text-center">{formatNumber(row.lastPrice)}</td>
                          <td
                            className={`p-2 border border-gray-600 text-center ${getStatusColor(row.status)}`}
                          >
                            {row.status || '-'}
                          </td>
                          <td className="p-2 border border-gray-600 text-center">{row.startTime || '-'}</td>
                          <td className="p-2 border border-gray-600 text-center">{row.endTime || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center p-4">No data available</div>
                )}
              </div>

              {/* Pagination Controls */}
              <div className="flex justify-between mt-4 text-sm text-gray-400">
                <div className="flex items-center space-x-2">
                  <span>
                    Showing {startIndex + 1} to{' '}
                    {Math.min(startIndex + entriesPerPage, filteredData.length)} of {filteredData.length}{' '}
                    entries
                  </span>
                </div>
                <div className="flex space-x-2">
                  <button
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${
                      currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                    <span>Previous</span>
                  </button>
                  <button
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${
                      currentPage === Math.ceil(filteredData.length / entriesPerPage) ||
                      Math.ceil(filteredData.length / entriesPerPage) === 0
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={() => handlePageChange('next')}
                    disabled={
                      currentPage === Math.ceil(filteredData.length / entriesPerPage) ||
                      Math.ceil(filteredData.length / entriesPerPage) === 0
                    }
                  >
                    <FaChevronRightIcon />
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  

      {/* Toast Component */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true} // Ensure newest toasts appear on top
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default History;

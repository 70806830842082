import React, { useState } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight, FaCopy, FaFileCsv, FaFileExcel, FaFilePdf, FaPrint, FaChevronLeft, FaChevronRight as FaChevronRightIcon } from 'react-icons/fa'; // Import React Icons

const Balance = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  // Example data
  const data = [
    // Example rows
    { no: '001', date: '2024-09-01', type: 'Deposit', amount: '$100', note: 'Initial deposit', status: 'Completed' },
    { no: '002', date: '2024-09-02', type: 'Withdrawal', amount: '$50', note: 'ATM withdrawal', status: 'Completed' },
    // Add more rows for testing pagination
  ];

  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  // Get current entries based on page
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = data.slice(startIndex, startIndex + entriesPerPage);

  const handlePageChange = (direction) => {
    setCurrentPage(prevPage => {
      if (direction === 'next') {
        return Math.min(prevPage + 1, totalPages);
      } else if (direction === 'prev') {
        return Math.max(prevPage - 1, 1);
      }
      return prevPage;
    });
  };

  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Balance</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Balance</span>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-grow flex flex-col">
          <Ticker />

          {/* Scrollable container */}
          <div className="overflow-auto bg-[#1F1F1F] border border-gray-600 rounded-lg p-4 mt-4">
            {/* Controls and Table container */}
            <div className="flex flex-col">
              {/* Controls for table */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
                {/* Search Input */}
                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="p-2 rounded bg-[#252525] text-white w-full sm:w-auto outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                  />
                </div>

                {/* Export Buttons */}
                <div className="flex space-x-2 mt-2 sm:mt-0 ml-auto">
                  <button className="bg-gray-600 text-white px-4 py-2 rounded flex items-center space-x-2">
                    <FaCopy />
                    <span className="hidden sm:inline">Copy</span>
                  </button>
                  <button className="bg-gray-600 text-white px-4 py-2 rounded flex items-center space-x-2">
                    <FaFileCsv />
                    <span className="hidden sm:inline">CSV</span>
                  </button>
                  <button className="bg-gray-600 text-white px-4 py-2 rounded flex items-center space-x-2">
                    <FaFileExcel />
                    <span className="hidden sm:inline">Excel</span>
                  </button>
                  <button className="bg-gray-600 text-white px-4 py-2 rounded flex items-center space-x-2">
                    <FaFilePdf />
                    <span className="hidden sm:inline">PDF</span>
                  </button>
                  <button className="bg-gray-600 text-white px-4 py-2 rounded flex items-center space-x-2">
                    <FaPrint />
                    <span className="hidden sm:inline">Print</span>
                  </button>
                </div>
              </div>

              {/* Table */}
              <div>
                <table className="min-w-full bg-[#1F1F1F] border border-gray-600">
                  <thead>
                    <tr>
                      <th className="p-2 border-r border-gray-600 text-left">No</th>
                      <th className="p-2 border-r border-gray-600 text-left">Date</th>
                      <th className="p-2 border-r border-gray-600 text-left">Type</th>
                      <th className="p-2 border-r border-gray-600 text-left">Amount</th>
                      <th className="p-2 border-r border-gray-600 text-left">Note</th>
                      <th className="p-2 border-r border-gray-600 text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.length > 0 ? (
                      currentEntries.map((row, index) => (
                        <tr key={index}>
                          <td className="p-2 border-b border-gray-600">{row.no}</td>
                          <td className="p-2 border-b border-gray-600">{row.date}</td>
                          <td className="p-2 border-b border-gray-600">{row.type}</td>
                          <td className="p-2 border-b border-gray-600">{row.amount}</td>
                          <td className="p-2 border-b border-gray-600">{row.note}</td>
                          <td className="p-2 border-b border-gray-600 text-green-400">{row.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="p-4 text-center">No data available in table</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination controls */}
              <div className="flex justify-between mt-4 text-sm text-gray-400">
                <div className="flex items-center space-x-2">
                  <span>Showing {startIndex + 1} to {Math.min(startIndex + entriesPerPage, totalEntries)} of {totalEntries} entries</span>
                </div>
                <div className="flex space-x-2">
                  <button 
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`} 
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                    <span>Previous</span>
                  </button>
                  <button 
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`} 
                    onClick={() => handlePageChange('next')}
                    disabled={currentPage === totalPages}
                  >
                    <FaChevronRightIcon />
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;

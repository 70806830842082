// src/components/Content.js
import React, { useRef, useState, useEffect } from 'react';
import Ticker from './Ticker'; // Adjust the path as necessary
import GridContainer from './Grid'; // Import the GridContainer component
import DetailsContainer from './Details'; // Import the DetailsContainer component
import WelcomeContainer from './Welcome'; // Import the Welcome component
import { useUser } from '../UserContext';


const TitleContainer = () => {
  return (
    <div className="bg-[#1F1F1F] border border-gray-500 p-4 rounded-lg mb-6">
      <h3 className="text-lg font-bold text-white">Dashboard Control Panel</h3>
    </div>
  );
};

const ReadOnlyForm = () => {
  const inputRef = useRef(null);
  const [url, setUrl] = useState('');
  const userData = useUser(); // Get user data from context

  useEffect(() => {
    // Construct the URL using the current origin and query parameters
    const currentOrigin = window.location.origin;
    const constructedUrl = `${currentOrigin}/register?reff=${userData?.username || "Error"}`;
    setUrl(constructedUrl);
  }, []);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div className="bg-[#1F1F1F] border border-gray-500 p-4 rounded-lg mb-6">
      <h3 className="text-lg font-bold text-white mb-4">Referral</h3>
      <div className="flex items-center">
        <input
          ref={inputRef}
          type="text"
          value={url}
          readOnly
          className="flex-grow bg-gray-800 text-white border border-gray-600 rounded-l-lg p-2"
        />
        <button
          onClick={handleCopy}
          className="bg-yellow-500 text-white font-bold py-2 px-4 rounded-r-lg ml-4 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
        >
          Copy Link
        </button>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <main className="bg-[#2F2F2F] text-white p-6 flex-grow">
      <Ticker />
      <TitleContainer />
      <ReadOnlyForm />
      <GridContainer />
      <DetailsContainer /> {/* Add the DetailsContainer component here */}
      <WelcomeContainer /> {/* Add the Welcome component here */}

    </main>
  );
};

export default Content;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaHome,
  FaUserCog,
  FaShieldAlt,
  FaExchangeAlt,
  FaDollarSign,
  FaNetworkWired,
  FaSignOutAlt,
  FaChevronDown,
} from 'react-icons/fa';
import axios from 'axios';
const origin = window.location.origin; // Get the origin domain dynamically

const API_URL = `${origin}/api/user-details.php`;

const menuConfig = [
  {
    name: 'Dashboard',
    to: '/',
    icon: <FaHome />,
  },
  {
    name: 'Settings',
    icon: <FaUserCog />,
    children: [
      {
        name: 'Profile',
        to: '/settings/profile',
        icon: <FaUserCog />, // Add an icon for the child
      },
      {
        name: 'Profile Image',
        to: '/settings/profile-image',
        icon: <FaUserCog />, // Add an icon for the child
      },
    ],
  },
  {
    name: 'Security',
    to: '/security',
    icon: <FaShieldAlt />,
  },
  {
    name: 'Trade',
    icon: <FaExchangeAlt />,
    children: [
      {
        name: 'Trade',
        to: '/trade',
        icon: <FaExchangeAlt />, // Add an icon for the child
      },
      {
        name: 'History',
        to: '/history',
        icon: <FaExchangeAlt />, // Add an icon for the child
      },
    ],
  },
  {
    name: 'Balance',
    icon: <FaDollarSign />,
    children: [
      {
        name: 'Deposit',
        to: '/deposit',
        icon: <FaDollarSign />, // Add an icon for the child
      },
      {
        name: 'Transfer',
        to: '/transfer',
        icon: <FaDollarSign />, // Add an icon for the child
      },
      {
        name: 'Withdrawal',
        to: '/withdrawal',
        icon: <FaDollarSign />, // Add an icon for the child
      },
      {
        name: 'History',
        to: '/historybalance',
        icon: <FaExchangeAlt />, // Add an icon for the child
      },
    ],
  },
  {
    name: 'Network',
    icon: <FaNetworkWired />,
    children: [
      {
        name: 'Referrals',
        to: '/referrals',
        icon: <FaNetworkWired />, // Add an icon for the child
      },
    ],
  },
  {
    name: 'Logout',
    to: '/logout',
    icon: <FaSignOutAlt />,
  },
];

const LeftAside = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('');
  const [expandedSection, setExpandedSection] = useState(null);
  const [userData, setUserData] = useState(null);

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
      }
    };

    fetchUserData();
  }, []);

  // Helper function to determine active menu item
  const determineActiveItem = (path) => {
    for (const item of menuConfig) {
      if (item.children) {
        for (const child of item.children) {
          if (path.startsWith(child.to)) {
            return item.name.toLowerCase();
          }
        }
      }
      if (item.to && path.startsWith(item.to)) {
        return item.name.toLowerCase();
      }
    }
    return 'dashboard'; // Default
  };

  // Set active item based on current path
  useEffect(() => {
    const path = location.pathname;
    const active = determineActiveItem(path);
    setActiveItem(active);

    // Automatically expand the active parent section
    menuConfig.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (path.startsWith(child.to)) {
            setExpandedSection(item.name);
          }
        });
      }
    });
  }, [location.pathname]);

  // Handle expanding/collapsing sections (only one can be expanded at a time)
  const handleExpand = (sectionName) => {
    setExpandedSection((prev) => (prev === sectionName ? null : sectionName));
  };

  // Render menu items recursively
  const renderMenu = (items, level = 0) => {
    return items.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      const isExpanded = expandedSection === item.name;
      const isActive = activeItem === item.name.toLowerCase();

      return (
        <li key={item.name}>
          <div
            className={`flex items-center mb-2 p-2 rounded transition-colors duration-200 relative ${
              isActive ? 'bg-[#303030]' : 'hover:bg-[#303030]'
            }`}
          >
            {level === 0 && (
              <div
                className={`absolute left-0 top-0 h-full w-1 ${
                  isActive ? 'bg-[#FFD700]' : 'bg-transparent'
                } rounded-l`}
              />
            )}
            {level === 0 ? (
              hasChildren ? (
                <>
                  <button className="flex items-center w-full text-white" onClick={() => handleExpand(item.name)}>
                    <div className="flex items-center justify-center bg-[#2B2B2B] rounded-full h-8 w-8 mr-2">
                      {item.icon}
                    </div>
                    <span>{item.name}</span>
                    <FaChevronDown
                      className={`ml-auto transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                    />
                  </button>
                </>
              ) : (
                <Link to={item.to} className="flex items-center w-full text-white">
                  <div className="flex items-center justify-center bg-[#2B2B2B] rounded-full h-8 w-8 mr-2">
                    {item.icon}
                  </div>
                  <span>{item.name}</span>
                </Link>
              )
            ) : (
              <Link to={item.to} className="flex items-center w-full text-white pl-8">
                <div className="flex items-center justify-center bg-[#2B2B2B] rounded-full h-6 w-6 mr-2">
                  {item.icon}
                </div>
                <span>{item.name}</span>
              </Link>
            )}
          </div>
          {hasChildren && isExpanded && (
            <ul className="pl-8">
              {renderMenu(item.children, level + 1)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <aside
      className={`bg-[#252525] text-white fixed md:relative top-0 left-0 z-40 p-4 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0 transition-transform duration-300 ease-in-out md:h-screen h-full w-64 flex flex-col`}
    >
      <button className="block md:hidden mb-4" onClick={toggleSidebar}>
        <FaChevronDown size={24} className="rotate-90" />
      </button>

      <div className="mb-4 text-center">
        <h3 className="text-lg font-bold">Welcome {userData?.full_name || 'User'}</h3>
        <div className="flex flex-col items-center mt-2 mb-2">
          <img
            src={userData?.profile_image || 'https://via.placeholder.com/50'}
            alt="Profile"
            className="rounded-full h-12 w-12 mb-2"
          />
          <div className="font-semibold">{userData?.full_name || 'Full Name'}</div>
          <div className="text-sm text-gray-400">@{userData?.username || 'Username'}</div>
        </div>
        <hr className="border-gray-600 my-2" />
      </div>

      <h3 className="text-lg mb-4">Menu</h3>

      <div className="flex flex-col flex-grow overflow-y-auto">
        <ul className="flex flex-col">
          {renderMenu(menuConfig)}
        </ul>
      </div>
    </aside>
  );
};

export default LeftAside;

// Transfer.js

import React, { useState } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight, FaArrowUp, FaUser, FaMoneyBill } from 'react-icons/fa'; // Import React Icons
import { useUser } from '../UserContext';

const Transfer = () => {
  const userData = useUser(); // Get user data from context
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [catatan, setCatatan] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTransfer = async () => {
    // Clear previous messages
    setMessage('');
    setError('');

    // Basic client-side validation
    if (!recipient || !amount) {
      setError('Recipient and amount are required.');
      return;
    }

    if (amount < 50000 || amount > 14999999999) {
      setError('Amount must be between Rp 50,000 and Rp 14,999,999,999.');
      return;
    }

    if (catatan.length > 250) {
      setError('Notes cannot exceed 250 characters.');
      return;
    }

    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token'); // Retrieve JWT from localStorage

      if (!token) {
        setError('Authentication token not found. Please log in again.');
        setIsSubmitting(false);
        return;
      }
      const origin = window.location.origin; // Get the origin domain dynamically    
      
      const response = await fetch(`${origin}/api/transfer.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          recipient,
          amount,
          catatan
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setMessage(`${data.message} Transaction ID: ${data.data.TransactionID}`);
        // Optionally, update user balance in context or state
        // Reset form fields
        setRecipient('');
        setAmount('');
        setCatatan('');
      } else {
        setError(data.message || 'Transfer failed.');
      }
    } catch (err) {
      setError('An unexpected error occurred.');
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Transfer</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Balance</span>
            <FaChevronRight className="mx-2" />
            <span>Transfer</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow">
          <Ticker />

          {/* Container for Transfer details */}
          <div className="bg-[#1F1F1F] border border-gray-600 rounded-lg p-4 mb-4">
            {/* Available Balance */}
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Available Balance</h2>
              <div className="flex items-center bg-gray-700 rounded p-3">
                <span className="text-gray-400 mr-2">Rp</span>
                <span className="text-white text-lg">{userData?.balance ? userData.balance.toLocaleString() : "0"}</span>
              </div>
            </div>
            
            {/* Recipient Username */}
            <div className="mb-4">
              <label htmlFor="recipient" className="text-sm text-gray-400 flex items-center">
                <FaUser className="text-gray-400 mr-2" />
                Username Recipient
              </label>
              <input
                id="recipient"
                type="text"
                placeholder="Username Recipient"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                className="w-full p-2 bg-[#252525] text-white rounded outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
              />
            </div>

            {/* Amount Transferred */}
            <div className="mb-4">
              <label htmlFor="amount" className="text-sm text-gray-400 flex items-center">
                <FaMoneyBill className="text-gray-400 mr-2" />
                Amount Transferred
              </label>
              <div className="flex items-center bg-[#252525] rounded">
                <span className="text-gray-400 p-2">Rp</span>
                <input
                  id="amount"
                  type="number"
                  placeholder="50,000"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="w-full p-2 bg-[#252525] text-white rounded-r outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                />
              </div>
            </div>

            {/* Notes */}
            <div className="mb-4">
              <label htmlFor="catatan" className="block text-sm text-gray-400 mb-1">Notes</label>
              <textarea
                id="catatan"
                placeholder="Enter your notes here (max length 250 characters)"
                value={catatan}
                onChange={(e) => setCatatan(e.target.value)}
                className="w-full p-2 bg-[#252525] text-white rounded outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                maxLength="250"
              />
            </div>

            {/* Notice */}
            <div className="text-sm text-gray-400 mb-4">
              <p className="font-bold">Notice:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Minimum Rp 50,000</li>
                <li>Maximum Rp 14,999,999,999</li>
                <li>Fee 1%</li>
              </ul>
            </div>

            {/* Success and Error Messages */}
            {message && <div className="mb-4 text-green-500">{message}</div>}
            {error && <div className="mb-4 text-red-500">{error}</div>}

            {/* Transfer Button */}
            <button
              type="button"
              onClick={handleTransfer}
              disabled={isSubmitting}
              className={`bg-blue-600 text-white px-4 py-2 rounded flex items-center space-x-2 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
            >
              <FaArrowUp />
              <span>Transfer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;

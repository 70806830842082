// src/pages/Trade.js

import React, { useState, useEffect, useRef } from 'react';
import Ticker from '../components/Ticker';
import {
  FaHome,
  FaChevronRight,
  FaDollarSign,
  FaClock,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  formatCoinGeckoSymbol,
  formatTradingViewSymbol,
  intervalToMilliseconds,
  getCurrentPrice,
} from '../utils/tradeUtils';
import {
  sendOrderToBackend,
  fetchActiveOrder,
  updateOrderInBackend,
  getBalanceFromBackend,
} from '../services/orderService';
import TradeModal from '../components/TradeModal';
import OrderLog from '../components/OrderLog';
import { useUser } from '../UserContext'; // Import useUser from UserContext

const MIN_BET = 50000; // Define the minimum bet

const Trade = () => {
  const [amount, setAmount] = useState('');
  const [interval, setIntervalValue] = useState('30 seconds');
  const [selectedPair, setSelectedPair] = useState('BTC/USD');
  const [orderLog, setOrderLog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingTime, setLoadingTime] = useState(0);
  const [orderType, setOrderType] = useState('');
  const [currentOrder, setCurrentOrder] = useState(null);

  const userData = useUser(); // Access userData from UserContext

  const chartContainerRef = useRef(null); // Chart container ref
  const tradingViewWidgetRef = useRef(null);
  const tradingViewScriptLoaded = useRef(false);

  const toastIdRef = useRef(null); // Ref for the toast notification
  const countdownRef = useRef(null); // Ref for the countdown interval

  useEffect(() => {
    const loadTradingViewScript = () => {
      if (tradingViewScriptLoaded.current) return;

      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.type = 'text/javascript';
      script.onload = () => {
        tradingViewScriptLoaded.current = true;
        renderChart();
      };
      script.onerror = () => {
        tradingViewScriptLoaded.current = false;
        toast.error('Failed to load chart. Please try again later.');
      };
      document.body.appendChild(script);
    };

    const renderChart = () => {
      if (window.TradingView && chartContainerRef.current) {
        if (tradingViewWidgetRef.current) {
          try {
            tradingViewWidgetRef.current.remove();
          } catch (error) {}
        }

        const tradingViewSymbol = formatTradingViewSymbol(selectedPair);
        tradingViewWidgetRef.current = new window.TradingView.widget({
          width: '100%',
          height: 440,
          symbol: tradingViewSymbol, // Use the TradingView-formatted symbol
          interval: intervalToTradingView(interval),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Use device's timezone
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#1F1F1F',
          enable_publishing: false,
          allow_symbol_change: true,
          studies: ['BB@tv-basicstudies', 'PSAR@tv-basicstudies'],
          container_id: chartContainerRef.current.id,
        });
      }
    };

    loadTradingViewScript();
    renderChart();

    return () => {
      if (tradingViewWidgetRef.current) {
        try {
          tradingViewWidgetRef.current.remove();
        } catch (error) {}
      }
      // Clear any active countdown intervals on unmount
      if (countdownRef.current) {
        clearInterval(countdownRef.current);
      }
    };
  }, [selectedPair, interval]);

  const intervalToTradingView = (interval) => {
    const intervals = {
      '30 seconds': '1', // TradingView may not support seconds; map to 1 minute
      '1 minute': '1',
      '2 minutes': '2',
      '5 minutes': '5',
      '15 minutes': '15',
      '30 minutes': '30',
      '1 hour': '60',
      '2 hours': '120',
      '4 hours': '240',
      '1 day': 'D',
      '1 week': 'W',
      '1 month': 'M',
    };
    return intervals[interval] || '1';
  };

  // Function to check for active orders on page load
  useEffect(() => {
    const checkActiveOrder = async () => {
      try {
        const activeOrders = await fetchActiveOrder();
        if (activeOrders?.orders?.length > 0) {
          const activeOrder = activeOrders.orders.find(
            (order) => order.status === 'Processing'
          );
          if (activeOrder) {
            setCurrentOrder(activeOrder);
            setOrderLog([activeOrder]);

            // Calculate time remaining until the endTime
            const timeRemaining =
              new Date(activeOrder.endTime).getTime() - new Date().getTime();
            if (timeRemaining > 0) {
              startCountdownToast(activeOrder, timeRemaining);
            } else {
              completeOrder(activeOrder); // Complete the order if endTime has already passed
            }
          }
        } else {
          setCurrentOrder(null); // No active order
        }
      } catch (error) {
        console.error('Error fetching active orders:', error);
      }
    };

    // Trigger the check for an active order only on page load
    checkActiveOrder();
  }, []);

  const startCountdownToast = (order, duration) => {
    let remainingTime = Math.ceil(duration / 1000);

    // If time is already up, complete the order immediately
    if (remainingTime <= 0) {
      completeOrder(order);
      return;
    }

    // Show countdown in a toast
    toastIdRef.current = toast.info(
      `Order in progress: ${remainingTime}s remaining`,
      {
        position: 'top-right',
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
      }
    );

    // Update countdown every second
    countdownRef.current = setInterval(() => {
      remainingTime -= 1;
      if (remainingTime <= 0) {
        clearInterval(countdownRef.current); // Clear the countdown interval
        toast.dismiss(toastIdRef.current); // Dismiss the countdown toast
        completeOrder(order); // Trigger the order completion when time is up
      } else {
        toast.update(toastIdRef.current, {
          render: `Order in progress: ${remainingTime}s remaining`,
        });
      }
    }, 1000);
  };

  const completeOrder = async (order) => {
    try {
      const coinGeckoSymbol = formatCoinGeckoSymbol(order.pair);
      const endPrice = await getCurrentPrice(coinGeckoSymbol); // Fetch the real last price
      console.log(`End Price at ${new Date().toLocaleString()}: $${endPrice}`);

      // Prepare the order data to be sent to the backend
      const updatedOrder = {
        ...order,
        lastPrice: endPrice.toFixed(6), // Set the last price
        // Don't set status here; it will be determined by the backend
      };

      console.log('Sending order to backend:', updatedOrder); // Console log for debugging

      // Trigger the update request to the backend
      const response = await updateOrderInBackend(updatedOrder);

      // Check if the response indicates success
      if (response.success) {
        // Successfully processed order, extract the status
        const orderStatus = response.status;

        // Update the order log and clear the current order
        setOrderLog([
          {
            ...updatedOrder,
            status: orderStatus,
          },
        ]);
        setCurrentOrder(null); // Clear the active order

        // Show success message with the status
        toast.success(`Order complete: ${orderStatus}`);
      } else {
        // Handle backend error
        toast.error(`Error: ${response.message}`);
      }

      // Fetch the updated balance from the backend
      try {
        const balanceData = await getBalanceFromBackend();
        if (balanceData.success) {
          const normalizedBalance = parseFloat(balanceData.balance);
          console.log('Updated Balance:', normalizedBalance);
        } else {
          console.error('Failed to retrieve updated balance');
        }
      } catch (balanceError) {
        console.error('Error fetching updated balance:', balanceError);
        toast.error('Error fetching updated balance.');
      }
    } catch (error) {
      console.error('Error completing order:', error);
      toast.error('Error completing order.');
    }
  };

  const handleTradeClick = async (type) => {
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error('Please enter a valid amount.');
      return;
    }

    // Add minimum bet check
    if (parseFloat(amount) < MIN_BET) {
      toast.error(`The minimum bet is Rp ${MIN_BET.toLocaleString()}.`);
      return;
    }

    if (!userData) {
      toast.error('User data is loading. Please wait.');
      return;
    }

    // Check if the user status is "Active" with the correct case
    if (userData.status !== 'Active') {
      toast.error('Your account is not active. Please contact support.');
      return;
    }

    try {
      // Fetch the latest balance from the backend
      const data = await getBalanceFromBackend();
      console.log('API Response:', data); // Log the entire response

      if (data.success) {
        // Directly use the balance as a number
        const normalizedBalance = parseFloat(data.balance);

        // Log the normalized balance for debugging
        console.log('Normalized Balance:', normalizedBalance);
        console.log('Entered Amount:', parseFloat(amount));

        // Compare the normalized balance with the entered amount
        if (parseFloat(amount) > normalizedBalance) {
          toast.error('Insufficient balance.');
          return;
        }

        setOrderType(type);
        setShowModal(true);
      } else {
        throw new Error('Failed to retrieve balance');
      }
    } catch (error) {
      console.error('Error checking balance:', error);
      toast.error('Error checking balance.');
    }
  };

  const confirmTrade = async () => {
    setShowModal(false);
    try {
      const coinGeckoSymbol = formatCoinGeckoSymbol(selectedPair);
      const startPrice = await getCurrentPrice(coinGeckoSymbol); // Fetch the real start price
      console.log(`Start Price at ${new Date().toLocaleString()}: $${startPrice}`);

      const orderId = `ORD-${Date.now()}`;
      const startTime = new Date();
      const endTime = new Date(
        startTime.getTime() + intervalToMilliseconds(interval)
      );

      const newOrder = {
        orderId,
        username: userData.username, // Include username
        type: orderType,
        amount,
        interval,
        pair: selectedPair,
        startPrice: startPrice.toFixed(6), // Set the start price
        lastPrice: null,
        status: 'Processing',
        startTime: formatLocalISO(startTime), // Use device's local time in ISO format with offset
        endTime: formatLocalISO(endTime), // Use device's local time in ISO format with offset
      };

      await sendOrderToBackend(newOrder);
      setOrderLog([newOrder]);
      setCurrentOrder(newOrder);

      startCountdownToast(newOrder, intervalToMilliseconds(interval)); // Start countdown toast
    } catch (error) {
      console.error('Error starting trade:', error);
      toast.error('Error starting trade.');
    }
  };

  // Utility function to format date in device's local timezone in ISO format with offset
  const formatLocalISO = (date) => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = (num) => String(Math.floor(Math.abs(num))).padStart(2, '0');
    const hours = pad(tzOffset / 60);
    const minutes = pad(tzOffset % 60);
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      diff +
      pad(hours) +
      ':' +
      pad(minutes)
    );
  };

  return (
    <div className="bg-[#2F2F2F] min-h-screen w-full text-white p-5">
      <ToastContainer />
      <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
        <h1 className="text-xl font-bold">Trade</h1>
        <div className="flex items-center text-sm text-gray-400">
          <FaHome className="mr-2" />
          <FaChevronRight className="mx-2" />
          <span>Trade</span>
        </div>
      </div>

      <Ticker />

      {/* User's Balance Display */}
      {userData && (
        <div className="mb-4 text-right text-lg">
          Balance: <span className="font-bold">Rp {parseFloat(userData.balance).toLocaleString()}</span>
        </div>
      )}

      {/* Pair Selection Dropdown */}
      <div className="bg-[#1F1F1F] border border-gray-600 p-2 h-12 rounded-lg mb-6 flex items-center">
        <select
          value={selectedPair}
          onChange={(e) => setSelectedPair(e.target.value)}
          className="bg-[#252525] border border-gray-600 text-white p-1 text-sm rounded w-full focus:bg-white focus:text-black"
        >
          <option value="BTC/USD">BTC/USD</option>
          <option value="ETH/USD">ETH/USD</option>
          <option value="LTC/USD">LTC/USD</option>
          <option value="XRP/USD">XRP/USD</option>
          <option value="ADA/USD">ADA/USD</option>
          <option value="SOL/USD">SOL/USD</option>
          <option value="DOGE/USD">DOGE/USD</option>
          <option value="DOT/USD">DOT/USD</option>
          <option value="SHIB/USD">SHIB/USD</option>
          <option value="TRX/USD">TRX/USD</option>
          <option value="LINK/USD">LINK/USD</option>
          <option value="MATIC/USD">MATIC/USD</option>
        </select>
      </div>

      <div
        id="tradingview-chart-container"
        ref={chartContainerRef}
        className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg mb-6"
        style={{ height: '500px' }}
      ></div>

      {/* Form for Amount, Interval, and Buy/Sell */}
      <div className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg mb-6">
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="flex w-full mr-0 md:mr-4 mb-4 md:mb-0">
            <div className="flex w-full">
              <div className="bg-[#252525] p-2 rounded-l border border-gray-600 flex items-center justify-center">
                <FaDollarSign className="text-white text-xl" />
              </div>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="bg-[#252525] border border-gray-600 text-white p-2 rounded-r w-full focus:bg-white focus:text-black"
                placeholder="Enter amount"
                disabled={currentOrder !== null} // Disable input if an active order exists
              />
            </div>
          </div>
          {/* Add helper text for minimum bet */}
          {amount && parseFloat(amount) < MIN_BET && (
            <p className="text-red-500 text-sm mt-1 md:mt-0 md:ml-2">
              Minimum bet is Rp {MIN_BET.toLocaleString()}.
            </p>
          )}

          <div className="flex w-full mr-0 md:mr-4 mb-4 md:mb-0">
            <div className="flex w-full">
              <div className="bg-[#252525] p-2 rounded-l border border-gray-600 flex items-center justify-center">
                <FaClock className="text-white text-xl" />
              </div>
              <select
                value={interval}
                onChange={(e) => setIntervalValue(e.target.value)}
                className="bg-[#252525] border border-gray-600 text-white p-2 rounded-r w-full focus:bg-white focus:text-black"
                disabled={currentOrder !== null} // Disable if an active order exists
              >
                <option value="30 seconds">30 seconds</option>
                <option value="1 minute">1 minute</option>
                <option value="2 minutes">2 minutes</option>
                <option value="5 minutes">5 minutes</option>
                <option value="15 minutes">15 minutes</option>
                <option value="30 minutes">30 minutes</option>
                <option value="1 hour">1 hour</option>
                <option value="2 hours">2 hours</option>
                <option value="4 hours">4 hours</option>
                <option value="1 day">1 day</option>
                <option value="1 week">1 week</option>
                <option value="1 month">1 month</option>
              </select>
            </div>
          </div>

          <div className="flex items-center justify-center md:justify-start mt-4 md:mt-0 md:ml-4">
            <button
              onClick={() => handleTradeClick('Buy')}
              className={`bg-green-600 text-white py-2 px-3 rounded hover:bg-green-700 mx-1 w-36 flex items-center justify-center ${
                currentOrder || (amount && parseFloat(amount) < MIN_BET) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentOrder !== null || (amount && parseFloat(amount) < MIN_BET)} // Disable if there's an active order or amount is below minimum
            >
              <FaArrowUp className="mr-2" />
              BUY 180%
            </button>
            <button
              onClick={() => handleTradeClick('Sell')}
              className={`bg-red-600 text-white py-2 px-3 rounded hover:bg-red-700 mx-1 w-36 flex items-center justify-center ${
                currentOrder || (amount && parseFloat(amount) < MIN_BET) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentOrder !== null || (amount && parseFloat(amount) < MIN_BET)} // Disable if there's an active order or amount is below minimum
            >
              <FaArrowDown className="mr-2" />
              SELL 180%
            </button>
          </div>
        </div>
      </div>

      <OrderLog orderLog={orderLog} loadingTime={loadingTime} />

      {showModal && (
        <TradeModal
          orderType={orderType}
          onClose={() => setShowModal(false)}
          onConfirm={confirmTrade}
        />
      )}
    </div>
  );
};

// Utility function to format date in device's local timezone in ISO format with offset
const formatLocalISO = (date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (num) => String(Math.floor(Math.abs(num))).padStart(2, '0');
  const hours = pad(tzOffset / 60);
  const minutes = pad(tzOffset % 60);
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(hours) +
    ':' +
    pad(minutes)
  );
};

export default Trade;

// src/components/AuthPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FaHome,
  FaChevronRight,
  FaCopy,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaPrint,
  FaChevronLeft,
  FaChevronRight as FaChevronRightIcon,
  FaInfoCircle,
} from 'react-icons/fa'; // Ensure you import necessary icons

const AuthPage = () => {
  const navigate = useNavigate();
  const [authMode, setAuthMode] = useState('login'); // 'login', 'register', or 'forgot-password'
  const [credentials, setCredentials] = useState({ 
    fullName: '', // Added Full Name
    username: '', 
    password: '', 
    email: '' 
  });
  const [errorMessage, setErrorMessage] = useState('');
  const origin = window.location.origin; // Get the origin domain dynamically

  const API_URL_LOGIN = `${origin}/api/login.php`; // Update with your backend URL
  const API_URL_REGISTER = `${origin}/api/register.php`; // Replace with register URL
  const API_URL_FORGOT_PASSWORD = `${origin}/api/forgot.php`; // Replace with forgot password URL

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let apiUrl;

    if (authMode === 'login') {
      apiUrl = API_URL_LOGIN;
    } else if (authMode === 'register') {
      apiUrl = API_URL_REGISTER;
    } else if (authMode === 'forgot-password') {
      apiUrl = API_URL_FORGOT_PASSWORD;
    }

    // Validation for required fields
    if (authMode === 'register') {
      if (!credentials.fullName || !credentials.username || !credentials.email || !credentials.password) {
        setErrorMessage('Please fill in all required fields.');
        return;
      }
    } else if (authMode === 'login') {
      if (!credentials.username || !credentials.password) {
        setErrorMessage('Please enter both username and password.');
        return;
      }
    } else if (authMode === 'forgot-password') {
      if (!credentials.email) {
        setErrorMessage('Please enter your email.');
        return;
      }
    }

    axios
      .post(apiUrl, credentials)
      .then((response) => {
        if (authMode === 'login') {
          localStorage.setItem('token', response.data.token);
          navigate('/');
        } else {
          setErrorMessage('Success! Please check your email or login.');
          toast.success('Operation successful! Please check your email or login.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: true,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: 'colored',
          });
          // Optionally, switch to login mode after successful registration or forgot password
          if (authMode === 'register') {
            setAuthMode('login');
            setCredentials({ fullName: '', username: '', email: '', password: '' });
          } else if (authMode === 'forgot-password') {
            setAuthMode('login');
            setCredentials({ fullName: '', username: '', email: '', password: '' });
          }
        }
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message || 'An error occurred. Please try again.'
        );
        toast.error(
          error.response?.data?.message || 'An error occurred. Please try again.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: true,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: 'colored',
          }
        );
      });
  };

  const switchAuthMode = (mode) => {
    setAuthMode(mode);
    setErrorMessage(''); // Clear error messages when switching modes
    setCredentials({ fullName: '', username: '', email: '', password: '' }); // Clear input fields
  };

  return (
    <div
      className="min-h-screen bg-gray-200 flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/221808/sky.jpg')",
      }}
    >
      <div 
        className="bg-gray-800 bg-opacity-90 p-8 rounded-lg shadow-lg w-full max-w-md border-2" 
        style={{ borderColor: '#D4AF37' }}
      >
        <div className="flex items-center justify-center mb-6">
          <img src="https://imgdb.net/storage/uploads/e4c7bc083ee74ad37404a65aee1532453fbbfc06f1e046b6944530c2276b1904.png" alt="Icon" className="w-38 h-auto" />
        </div>

        <h2 
          className="text-2xl font-bold mb-4 text-center" 
          style={{ color: '#D4AF37' }}
        >
          {authMode === 'login' ? 'Login' : authMode === 'register' ? 'Register' : 'Forgot Password'}
        </h2>

        {errorMessage && (
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
        )}

        <form onSubmit={handleSubmit}>
          {/* Full Name Field for Register */}
          {authMode === 'register' && (
            <div className="mb-4">
              <label 
                className="block font-bold mb-2" 
                htmlFor="fullName" 
                style={{ color: '#D4AF37' }}
              >
                Full Name
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="fullName"
                type="text"
                name="fullName"
                value={credentials.fullName}
                onChange={handleChange}
                required
                autoComplete="name"
                style={{ borderColor: '#D4AF37' }}
              />
            </div>
          )}

          {/* Username Field */}
          {(authMode === 'login' || authMode === 'register') && (
            <div className="mb-4">
              <label 
                className="block font-bold mb-2" 
                htmlFor="username" 
                style={{ color: '#D4AF37' }}
              >
                Username
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                name="username"
                value={credentials.username}
                onChange={handleChange}
                required
                autoComplete="username"
                style={{ borderColor: '#D4AF37' }}
              />
            </div>
          )}

          {/* Email Field for Register and Forgot Password */}
          {(authMode === 'register' || authMode === 'forgot-password') && (
            <div className="mb-4">
              <label 
                className="block font-bold mb-2" 
                htmlFor="email" 
                style={{ color: '#D4AF37' }}
              >
                Email
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                required
                autoComplete="email"
                style={{ borderColor: '#D4AF37' }}
              />
            </div>
          )}

          {/* Password Field */}
          {(authMode === 'login' || authMode === 'register') && (
            <div className="mb-6">
              <label 
                className="block font-bold mb-2" 
                htmlFor="password" 
                style={{ color: '#D4AF37' }}
              >
                Password
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
                autoComplete={authMode === 'login' ? "current-password" : "new-password"}
                style={{ borderColor: '#D4AF37' }}
              />
            </div>
          )}

          {/* Submit Button */}
          <div className="flex items-center justify-between mb-4">
            <button
              className="bg-gold-500 hover:bg-gold-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
              style={{ backgroundColor: '#D4AF37', borderColor: '#D4AF37' }}
            >
              {authMode === 'login' 
                ? 'Login' 
                : authMode === 'register' 
                  ? 'Register' 
                  : 'Reset Password'}
            </button>
          </div>

          {/* Toggle between forms */}
          <div className="flex items-center justify-between">
            {authMode === 'login' && (
              <>
                <button
                  className="text-gold-500 hover:text-gold-700 font-bold"
                  type="button"
                  onClick={() => switchAuthMode('register')}
                  style={{ color: '#D4AF37' }}
                >
                  Register
                </button>
                <button
                  className="text-gold-500 hover:text-gold-700 font-bold"
                  type="button"
                  onClick={() => switchAuthMode('forgot-password')}
                  style={{ color: '#D4AF37' }}
                >
                  Forgot Password?
                </button>
              </>
            )}
            {authMode === 'register' && (
              <button
                className="text-gold-500 hover:text-gold-700 font-bold"
                type="button"
                onClick={() => switchAuthMode('login')}
                style={{ color: '#D4AF37' }}
              >
                Already have an account? Login
              </button>
            )}
            {authMode === 'forgot-password' && (
              <button
                className="text-gold-500 hover:text-gold-700 font-bold"
                type="button"
                onClick={() => switchAuthMode('login')}
                style={{ color: '#D4AF37' }}
              >
                Back to Login
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Toast Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true} // **Ensure newest toasts appear on top**
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default AuthPage;

import React, { useState, useEffect } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as needed
import {
  FaHome,
  FaChevronRight,
  FaCopy,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaPrint,
  FaChevronLeft,
  FaChevronRight as FaChevronRightIcon,
  FaInfoCircle,
} from 'react-icons/fa'; // Import React Icons
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const origin = window.location.origin; // Get the origin domain dynamically

const API_URL = `${origin}/api/balance_history.php`; // Replace with the correct URL

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const [data, setData] = useState([]); // State to store fetched history data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // States for Details Modal
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  // Function to fetch history data
  const fetchHistoryData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    if (!token) {
      setError('Authentication token not found.');
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('token', token); // Send token in form data

      const response = await fetch(API_URL, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        // **Sort data in descending order based on 'time'**
        const sortedData = result.data.sort((a, b) => {
          const dateA = new Date(a.time);
          const dateB = new Date(b.time);
          return dateB - dateA; // Descending order: newest first
        });

        setData(sortedData);
        setLoading(false);
      } else {
        setError(result.message || 'Failed to fetch history data.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching history data:', error);
      setError('An error occurred while fetching history data.');
      setLoading(false);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchHistoryData(); // Initial data fetch
  }, []); // Empty dependency array ensures this runs only once

  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  // Handle search functionality
  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
  );

  // Get current entries based on page and filtered data
  const startIndex = (currentPage - 1) * entriesPerPage;
  const currentEntries = filteredData.slice(startIndex, startIndex + entriesPerPage);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === 'next') {
        return Math.min(prevPage + 1, Math.ceil(filteredData.length / entriesPerPage));
      } else if (direction === 'prev') {
        return Math.max(prevPage - 1, 1);
      }
      return prevPage;
    });
  };

  // Function to format numbers by removing decimals
  const formatNumber = (num) => {
    if (num === null || num === undefined) return '-';
    const parsed = parseFloat(num);
    if (isNaN(parsed)) return '-';
    return Math.round(parsed).toLocaleString();
  };

  // Function to determine status color
  const getStatusColor = (status) => {
    if (!status) return 'text-white'; // Handle null/undefined
    switch (status.toLowerCase()) {
      case 'pending':
        return 'text-orange-500';
      case 'ditolak':
        return 'text-red-500';
      case 'success':
        return 'text-green-500';
      default:
        return 'text-white';
    }
  };

  // Function to determine type color
  const getTypeColor = (type) => {
    if (!type) return 'text-white'; // Handle null/undefined
    switch (type.toLowerCase()) {
      case 'deposit':
        return 'text-green-500';
      case 'withdraw':
        return 'text-red-500';
      case 'transfer':
        return 'text-red-500'; // Assign red color to 'Transfer'
      default:
        return 'text-white';
    }
  };

  // Handler to open details modal
  const handleOpenDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setIsDetailsModalOpen(true);
  };

  // Handler to close details modal
  const handleCloseDetails = () => {
    setSelectedTransaction(null);
    setIsDetailsModalOpen(false);
  };

  // Handler to copy bank number to clipboard
  const handleCopyBankNumber = (bankNumber) => {
    if (!bankNumber) {
      toast.error('No bank number to copy.');
      return;
    }
    navigator.clipboard
      .writeText(bankNumber)
      .then(() => {
        toast.success('Bank number copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy bank number.');
      });
  };

  return (
    <div className="bg-[#1F1F1F] min-h-screen w-full text-white p-5 box-border overflow-x-hidden">
      <div className="flex flex-col h-full">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">History</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>History</span>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-grow flex flex-col">
          <Ticker />

          {/* Scrollable Container */}
          <div className="flex-grow overflow-auto bg-[#1F1F1F] border border-gray-600 rounded-lg p-4 mt-4">
            {/* Control and Table Container */}
            <div className="flex flex-col">
              {/* Table Header */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
                {/* Search Input */}
                <div className="flex-1">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1); // Reset to first page on search
                    }}
                    className="p-2 rounded bg-[#252525] text-white w-full sm:w-auto outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                  />
                </div>

                {/* Export Buttons (Disabled) */}
                <div className="flex space-x-2 mt-2 sm:mt-0 ml-auto">
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaCopy />
                    <span className="hidden sm:inline">Copy</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFileCsv />
                    <span className="hidden sm:inline">CSV</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFileExcel />
                    <span className="hidden sm:inline">Excel</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaFilePdf />
                    <span className="hidden sm:inline">PDF</span>
                  </button>
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded flex items-center space-x-2 cursor-not-allowed opacity-50"
                    disabled
                  >
                    <FaPrint />
                    <span className="hidden sm:inline">Print</span>
                  </button>
                </div>
              </div>

              {/* Table */}
              <div className="overflow-x-auto">
                {loading ? (
                  <div className="flex justify-center items-center p-4">
                    <svg
                      className="animate-spin h-8 w-8 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  </div>
                ) : error ? (
                  <div className="text-center text-red-400 p-4">{error}</div>
                ) : filteredData.length > 0 ? (
                  <table className="min-w-full bg-[#1F1F1F] border-collapse border border-gray-600">
                    <thead>
                      <tr>
                        <th className="p-2 border border-gray-600 text-center">Transaction ID</th>
                        <th className="p-2 border border-gray-600 text-center">Type</th>
                        <th className="p-2 border border-gray-600 text-center">Username</th>
                        <th className="p-2 border border-gray-600 text-center">Amount</th>
                        <th className="p-2 border border-gray-600 text-center">Catatan</th>
                        <th className="p-2 border border-gray-600 text-center">Status</th>
                        <th className="p-2 border border-gray-600 text-center">Time</th>
                        <th className="p-2 border border-gray-600 text-center">Nomor Bank</th>
                        <th className="p-2 border border-gray-600 text-center">Nama Bank</th>
                        <th className="p-2 border border-gray-600 text-center">Nama Lengkap</th>
                        <th className="p-2 border border-gray-600 text-center">Actions</th> {/* New Actions Column */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentEntries.map((row, index) => (
                        <tr key={index}>
                          <td className="p-2 border border-gray-600 text-center">{row.TransactionID || '-'}</td>
                          <td
                            className={`p-2 border border-gray-600 text-center ${getTypeColor(row.Type)}`}
                          >
                            {row.Type || '-'}
                          </td>
                          <td className="p-2 border border-gray-600 text-center">{row.username || '-'}</td>
                          <td className="p-2 border border-gray-600 text-center">{formatNumber(row.Amount)}</td>
                          <td className="p-2 border border-gray-600 text-center">{row.catatan || '-'}</td>
                          <td
                            className={`p-2 border border-gray-600 text-center ${getStatusColor(row.status)}`}
                          >
                            {row.status || '-'}
                          </td>
                          <td className="p-2 border border-gray-600 text-center">{row.time || '-'}</td>
                          {/* Nomor Bank with Copy Button */}
                          <td className="p-2 border border-gray-600 text-center flex items-center justify-center">
                            <span className="mr-2">{row.TujuanBank || '-'}</span>
                            <button
                              onClick={() => handleCopyBankNumber(row.TujuanBank)}
                              className="flex items-center justify-center px-2 py-1 bg-gray-700 text-white rounded hover:bg-gray-800 transition-colors duration-300"
                              aria-label="Copy Bank Number"
                            >
                              <FaCopy />
                            </button>
                          </td>
                          <td className="p-2 border border-gray-600 text-center">{row.NamaBank || '-'}</td>
                          <td className="p-2 border border-gray-600 text-center">{row.NamaLengkap || '-'}</td>
                          <td className="p-2 border border-gray-600 text-center">
                            <button
                              onClick={() => handleOpenDetails(row)}
                              className="flex items-center justify-center px-3 py-1 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors duration-300"
                            >
                              <FaInfoCircle className="mr-1" />
                              <span className="hidden sm:inline">Details</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center p-4">No data available</div>
                )}
              </div>

              {/* Pagination Controls */}
              <div className="flex justify-between mt-4 text-sm text-gray-400">
                <div className="flex items-center space-x-2">
                  <span>
                    Showing {startIndex + 1} to{' '}
                    {Math.min(startIndex + entriesPerPage, filteredData.length)} of {filteredData.length}{' '}
                    entries
                  </span>
                </div>
                <div className="flex space-x-2">
                  <button
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${
                      currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                    <span>Previous</span>
                  </button>
                  <button
                    className={`px-4 py-2 bg-gray-600 rounded flex items-center space-x-2 ${
                      currentPage === Math.ceil(filteredData.length / entriesPerPage) ||
                      Math.ceil(filteredData.length / entriesPerPage) === 0
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={() => handlePageChange('next')}
                    disabled={
                      currentPage === Math.ceil(filteredData.length / entriesPerPage) ||
                      Math.ceil(filteredData.length / entriesPerPage) === 0
                    }
                  >
                    <FaChevronRightIcon />
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Details Modal */}
      {isDetailsModalOpen && selectedTransaction && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#2D2D2D] border border-gray-600 rounded-lg p-8 w-11/12 max-w-md shadow-xl transform transition-all duration-300">
            <h3 className="text-xl font-semibold mb-4">Transaction Details</h3>
            <div className="mb-4">
              <p>
                <strong>Transaction ID:</strong> {selectedTransaction.TransactionID || '-'}
              </p>
              <p>
                <strong>Type:</strong>{' '}
                <span className={getTypeColor(selectedTransaction.Type)}>
                  {selectedTransaction.Type || '-'}
                </span>
              </p>
              <p>
                <strong>Username:</strong> {selectedTransaction.username || '-'}
              </p>
              <p>
                <strong>Amount:</strong> Rp {formatNumber(selectedTransaction.Amount)}
              </p>
              <p>
                <strong>Catatan:</strong> {selectedTransaction.catatan || '-'}
              </p>
              <p>
                <strong>Status:</strong>{' '}
                <span className={getStatusColor(selectedTransaction.status)}>
                  {selectedTransaction.status || '-'}
                </span>
              </p>
              <p>
                <strong>Time:</strong> {selectedTransaction.time || '-'}
              </p>
            </div>

            {/* Bank Information */}
            <div className="border-t border-gray-500 pt-4 mb-4">
              <h4 className="text-md font-semibold mb-2">Bank Details:</h4>
              <div className="space-y-1">
                <p>
                  <strong>Nama Bank:</strong> {selectedTransaction.NamaBank || '-'}
                </p>
                <p>
                  <strong>Nomor Bank:</strong> {selectedTransaction.TujuanBank || '-'}
                </p>
                <p>
                  <strong>Nama Lengkap:</strong> {selectedTransaction.NamaLengkap || '-'}
                </p>
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCloseDetails}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true} // **Ensure newest toasts appear on top**
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default History;

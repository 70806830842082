import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const OrderLog = ({ orderLog, loadingTime }) => {
  return (
    <div className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Active Order Log</h2>
      {orderLog.length > 0 ? (
        <div>
          {orderLog.map((order, index) => (
            <div key={index} className="mb-4">
              <p>
                <strong>Order ID:</strong> {order.orderId}
              </p>
              <p>
                <strong>Type:</strong> {order.type}
              </p>
              <p>
                <strong>Amount:</strong> {order.amount}
              </p>
              <p>
                <strong>Pair:</strong> {order.pair}
              </p>
              <p>
                <strong>Starting Price:</strong> {order.startPrice}
              </p>
              <p>
                <strong>Last Price:</strong> {order.lastPrice !== null ? order.lastPrice : '---'}
              </p>
              <p>
                <strong>Status:</strong> {order.status === 'Processing' ? (
                  <span className="flex items-center">
                    <FaSpinner className="animate-spin text-red-500 mr-1" style={{ fontSize: '1em' }} />
                    <span className="text-red-500">Processing...</span>
                  </span>
                ) : (
                  <span>{order.status}</span>
                )}
              </p>
              <p>
                <strong>Start Time:</strong> {new Date(order.startTime).toLocaleString()}
              </p>
              <p>
                <strong>End Time:</strong> {new Date(order.endTime).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-400">No active orders to display.</p>
      )}
    </div>
  );
};

export default OrderLog;

import React from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight } from 'react-icons/fa'; // Import React Icons

const Profile = () => {
  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Profile</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Settings</span>
            <FaChevronRight className="mx-2" />
            <span>Profile</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow">
          <Ticker />
          {/* Content for the Profile page */}
        </div>
      </div>
    </div>
  );
};

export default Profile;

// src/UserContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const origin = window.location.origin; // Get the origin domain dynamically
  const API_URL = `${origin}/api/protected-route.php`; // Use backticks for template literals
  
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      // If no token, navigate to login unless already on login
      if (location.pathname !== '/login') {
        navigate('/login');
      }
      return;
    }

    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        // **Assign Default Profile Image if not present**
        if (!response.data.data.profile_image) {
          response.data.data.profile_image = 'https://cdn-icons-png.flaticon.com/128/4140/4140037.png';
        }

        setUserData(response.data.data);

        // **Check if user status is 'Inactive'**
        if (
          response.data.data.status &&
          response.data.data.status.toLowerCase() === 'inactive'
        ) {
          // Avoid redirecting if already on /kyc
          if (location.pathname !== '/kyc') {
            navigate('/kyc');
          }
        }
      } else {
        // If response doesn't contain data, navigate to login
        if (location.pathname !== '/login') {
          navigate('/login');
        }
      }

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
      } else {
        console.error('Access Declined:', response.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // On error (e.g., invalid token), navigate to login
      if (location.pathname !== '/login') {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 10000); // Fetch every 10 seconds
    return () => clearInterval(intervalId);
  }, [navigate, location.pathname]); // Add location.pathname as dependency

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

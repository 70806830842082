import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remove the token from localStorage
    localStorage.removeItem('token');

    // Optionally, you can also clear the entire localStorage if needed
    // localStorage.clear();

    // Redirect the user to the login page
    navigate('/login');
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default Logout;

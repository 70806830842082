import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet, Navigate } from 'react-router-dom';
import Header from './components/Header';
import LeftAside from './components/LeftAside';
import Content from './components/Content';
import Footer from './components/Footer';

// Importing Page Components
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import ProfileImage from './pages/ProfileImage';
import Trade from './pages/Trade';
import Balance from './pages/Balance';
import History from './pages/History';
import HistoryBalance from './pages/HistoryBalance';
import Kyc from './pages/kyc';

import Transfer from './pages/Transfer';
import Deposit from './pages/Deposit';
import Withdrawal from './pages/Withdrawal';
import Network from './pages/Network';
import Security from './pages/Security';
import Referrals from './pages/Referrals';
import Logout from './pages/Logout';
import LoginPage from './auth/LoginPage';
import { UserProvider } from './UserContext';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      <UserProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage />} />

          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route element={<MainLayout isOpen={isOpen} toggleSidebar={toggleSidebar} />}>
              <Route path="/" element={<Content />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/profile" element={<Profile />} />
              <Route path="/settings/profile-image" element={<ProfileImage />} />
              <Route path="/security" element={<Security />} />
              <Route path="/trade" element={<Trade />} />
              <Route path="/balance" element={<Balance />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/kyc" element={<Kyc />} />

              <Route path="/transfer" element={<Transfer />} />
              <Route path="/withdrawal" element={<Withdrawal />} />
              <Route path="/network" element={<Network />} />
              <Route path="/referrals" element={<Referrals />} />
              <Route path="/logout" element={<Logout />} />
              {/* History, Transfer, Deposit, and Withdrawal pages with special layout treatment */}
              <Route path="/history" element={<History />} />
              <Route path="/historybalance" element={<HistoryBalance />} />

            </Route>
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
}

const MainLayout = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const isAuthPage = ['/login', '/register', '/forgot-password'].includes(location.pathname);

  // Detect if the current page is History, Transfer, Deposit, or Withdrawal
  const isSpecialPage = ['/history', '/transfer', '/withdrawal', '/deposit', '/historybalance', '/HistoryBalance', '/'].includes(location.pathname);

  return isAuthPage ? (
    <Outlet />
  ) : (
    <>
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex">
        <LeftAside isOpen={isOpen} toggleSidebar={toggleSidebar} />

        {/* Apply a different style when on the History, Transfer, Deposit, or Withdrawal pages */}
        <main className={`flex-1 ${isSpecialPage ? 'overflow-auto min-h-screen' : ''}`}>
          <Outlet />
        </main>
      </div>
      <Footer />
    </>
  );
};

const PrivateRoute = () => {
  // Replace with your actual authentication logic
  const isAuthenticated = true; // This should be replaced with actual auth logic

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default App;

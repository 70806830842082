import React from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight } from 'react-icons/fa'; // Import React Icons

const Security = () => {
  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Security</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Security</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow">
          <Ticker />
          
          {/* Two-Factor Authentication Section */}
          <div className="bg-[#1F1F1F] border border-gray-600 p-6 rounded-lg mb-6">
            <h2 className="text-lg font-semibold mb-4">Two Factor Setup</h2>
            <p className="mb-4">To set up two-factor authentication, you first need to download Google Authenticator:</p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                <a 
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-[#FFD700] hover:underline"
                >
                  Google Authenticator for Android (Play Store)
                </a>
              </li>
              <li>
                <a 
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-[#FFD700] hover:underline"
                >
                  Google Authenticator for iOS (Apple App Store)
                </a>
              </li>
            </ul>
            <p className="mb-4">
              Then scan the below barcode or, if you are not able to scan the barcode, you can enter the "Security Key" manually.
            </p>
            <div className="flex items-center mb-4">
              <img 
                src="path/to/your/barcode-image.png" 
                alt="2FA Barcode" 
                className="w-1/2 md:w-1/4 h-auto mr-4" 
              />
              <div>
                <p className="mb-2">
                  Security Key: <span className="font-semibold">TJSIG5UMPXO47K5X</span> (Time Based Code)
                </p>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="2fa-code" className="block text-sm font-medium mb-1">Enter 2FA Code</label>
              <input 
                type="text" 
                id="2fa-code" 
                className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-white focus:text-black" 
              />
            </div>
            <div className="flex items-center mb-4">
              <label htmlFor="enable-2fa" className="mr-2">Enable 2FA</label>
              <input 
                type="checkbox" 
                id="enable-2fa" 
                className="form-checkbox text-[#FFD700]" 
              />
            </div>
            <button 
              type="button" 
              className="bg-[#FFD700] text-black py-2 px-4 rounded flex items-center"
            >
              <span>Update Settings</span>
            </button>
          </div>
          
          {/* Google Authenticator Guide */}
          <div className="bg-[#1F1F1F] border border-gray-600 p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Google Authenticator Guide</h3>
            <p className="mb-4">Install Google Authenticator for Android or Apple and open Google Authenticator.</p>
            <ol className="list-decimal ml-5 mb-4">
              <li>Go to Menu Setup Account</li>
              <li>Choose Scan a barcode option, and scan the barcode shown on this page</li>
              <li>If you are unable to scan the barcode: Choose Enter provided key and type in the "Security Key"</li>
              <li>A six-digit number will now appear in your Google Authenticator app home screen, enter this code into the 2FA form on this page</li>
              <li>Every time you log in to APTRADE, you must enter the new 2FA code from your Google Authenticator into the 2FA box on the login form</li>
              <li>Contact administrator if you want to reset your 2FA Google Authenticator.</li>
            </ol>
            <p className="font-medium text-yellow-400 mb-4">Important: Save this secret code for future reference</p>
            <p>Note: No Google account is required to use Google Authenticator; skip any Google logins.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;

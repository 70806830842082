import React, { useState, useEffect } from 'react';
import { FaHome, FaChevronRight, FaArrowUp } from 'react-icons/fa'; // Import React Icons
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Pastikan Anda telah menginstal axios
const origin = window.location.origin; // Get the origin domain dynamically

const API_URL = `${origin}/api/request_deposit.php`; // Ganti dengan URL yang benar

const Deposit = () => {
  const [amount, setAmount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false); // Melacak apakah deposit sedang diproses
  const [rekening, setRekening] = useState([]); // Menyimpan detail rekening sebagai array
  const [selectedBank, setSelectedBank] = useState(null); // Menyimpan bank yang dipilih
  const [hasPendingDeposit, setHasPendingDeposit] = useState(false); // Menyimpan status deposit pending
  const navigate = useNavigate(); // Untuk navigasi

  // Mengambil detail rekening dan cek deposit pending saat komponen dipasang
  useEffect(() => {
    const fetchRekening = async () => {
      try {
        const token = localStorage.getItem('token'); // Pastikan token disimpan di sini setelah login

        if (!token) {
          setHasPendingDeposit(true);
          toast.error('Token otentikasi tidak ditemukan. Silakan login kembali.');
          return;
        }

        const response = await axios.get(API_URL, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('Response:', response.data); // Debugging

        if (response.data.success) {
          setRekening(response.data.data.bankDetails);
          console.log('Bank Details:', response.data.data.bankDetails); // Debugging

          // Set bank pertama sebagai default jika tersedia
          if (response.data.data.bankDetails.length > 0) {
            setSelectedBank(response.data.data.bankDetails[0]);
            console.log('Selected Bank:', response.data.data.bankDetails[0]); // Debugging
          }

          // Cek apakah ada deposit pending
          if (response.data.data.pendingDeposits.length > 0) {
            setHasPendingDeposit(true);
            toast.warn('Anda memiliki deposit yang sedang diproses. Silakan selesaikan deposit sebelumnya sebelum melakukan deposit baru.');
          }
        } else {
          toast.error(response.data.message || 'Gagal mengambil detail rekening.');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Terjadi kesalahan saat mengambil detail rekening.');
        }
        console.error('Error fetching rekening:', error); // Debugging
      }
    };

    fetchRekening();
  }, []);

  // Handler untuk input jumlah
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Handler untuk memilih bank
  const handleBankChange = (e) => {
    const bankId = e.target.value;
    console.log('Selected Bank ID:', bankId); // Debugging
    const bank = rekening.find(b => b.id === bankId); // Perbandingan string dengan string
    console.log('Found Bank:', bank); // Debugging
    setSelectedBank(bank);
  };

  // Handler pengiriman form
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validasi input
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount)) {
      toast.error('Silakan masukkan angka yang valid untuk jumlah.');
      return;
    }
    if (numericAmount < 50000) {
      toast.error('Jumlah deposit minimum adalah Rp 50.000.');
      return;
    }
    if (numericAmount > 19999999999) {
      toast.error('Jumlah deposit maksimum adalah Rp 19.999.999.999.');
      return;
    }
    if (!selectedBank) {
      toast.error('Silakan pilih bank tujuan deposit.');
      return;
    }

    // Buka modal konfirmasi
    setIsModalOpen(true);
  };

  // Handler untuk konfirmasi deposit
  const handleConfirmDeposit = () => {
    setIsPending(true);

    // Mengambil token dari localStorage atau metode penyimpanan lainnya
    const token = localStorage.getItem('token'); // Pastikan token disimpan di sini setelah login

    if (!token) {
      toast.error('Token otentikasi tidak ditemukan. Silakan login kembali.');
      setIsPending(false);
      setIsModalOpen(false);
      return;
    }

    // Mengirimkan permintaan POST ke request_deposit.php
    axios.post(API_URL, {
      Amount: amount,
      catatan: 'Deposit via website', // Ganti dengan catatan yang sesuai jika diperlukan
      BankID: selectedBank.id // Pastikan 'id' dikirim sebagai string
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      console.log('POST Response:', response.data); // Debugging
      setIsPending(false);
      setIsModalOpen(false);
      if (response.data.success) {
        toast.success(`Deposit berhasil. ID Transaksi: ${response.data.data.TransactionID}`);
        // Navigasi ke HistoryBalance setelah 2 detik
        setTimeout(() => {
          navigate('/HistoryBalance');
        }, 2000);
      } else {
        toast.error(response.data.message || 'Deposit gagal.');
      }
    })
    .catch(error => {
      setIsPending(false);
      setIsModalOpen(false);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Terjadi kesalahan saat memproses deposit.');
      }
      console.error('Error during deposit:', error); // Debugging
    });
  };

  // Handler untuk membatalkan deposit
  const handleCancelDeposit = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-[#1F1F1F] min-h-screen w-full text-white p-5 box-border overflow-auto">
      <div className="flex flex-col h-full">
        {/* Bagian Header */}
        <div className="border-b border-gray-600 pb-2 mb-4">
          <h1 className="text-xl font-bold">Deposit</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Saldo</span>
            <FaChevronRight className="mx-2" />
            <span>Deposit</span>
          </div>
        </div>

        {/* Konten Utama */}
        <div className="flex-grow flex flex-col">
          {/* Bagian Tambah Saldo */}
          <div className="bg-[#2D2D2D] border border-gray-600 rounded-lg p-6 mt-4 shadow-lg">
            <h2 className="text-lg font-bold mb-4">Tambah Saldo</h2>
            {/* Tampilkan pesan jika ada deposit pending */}
            {hasPendingDeposit && (
              <div className="mb-4 p-4 bg-red-600 rounded">
                Anda memiliki deposit yang sedang diproses. Silakan selesaikan deposit sebelumnya sebelum melakukan deposit baru.
              </div>
            )}
            <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
              {/* Input Jumlah */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="amount" className="text-sm text-gray-400">
                  Jumlah
                </label>
                <div className="flex items-center bg-gray-700 rounded">
                  <span className="text-gray-400 p-2">Rp</span>
                  <input
                    id="amount"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Masukkan jumlah"
                    className="flex-1 p-2 bg-gray-700 text-white rounded-r outline-none"
                    min="50000" // Jumlah minimum
                    max="19999999999" // Jumlah maksimum
                    step="1000" // Langkah nilai yang valid
                    required
                    disabled={hasPendingDeposit} // Nonaktifkan input jika ada deposit pending
                  />
                </div>
              </div>

              {/* Dropdown Seleksi Bank */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="bank" className="text-sm text-gray-400">
                  Pilih Bank Tujuan
                </label>
                <select
                  id="bank"
                  value={selectedBank ? selectedBank.id : ''}
                  onChange={handleBankChange}
                  className="p-2 rounded bg-gray-700 text-white outline-none focus:bg-[#ffffff] focus:text-[#000000] transition-colors duration-300"
                  required
                  disabled={hasPendingDeposit || rekening.length === 0} // Nonaktifkan jika ada deposit pending atau tidak ada bank
                >
                  {rekening.length > 0 ? (
                    rekening.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                        {bank.NamaBank} - {bank.TujuanBank}
                      </option>
                    ))
                  ) : (
                    <option value="">Tidak ada bank tersedia</option>
                  )}
                </select>
              </div>

              {/* Bagian Pemberitahuan */}
              <div className="text-sm text-gray-400">
                <p>Pemberitahuan:</p>
                <ul className="list-disc list-inside ml-4">
                  <li>Min Rp 50.000</li>
                  <li>Maks Rp 19.999.999.999</li>
                  <li>Biaya 0%</li>
                </ul>
              </div>

              {/* Tombol Submit */}
              <button
                type="submit"
                className={`w-40 bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2 hover:bg-green-700 transition-colors duration-300 ${
                  isPending || hasPendingDeposit ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isPending || hasPendingDeposit} // Nonaktifkan tombol jika sedang diproses atau ada deposit pending
              >
                <FaArrowUp />
                <span>Tambah Saldo</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Modal Konfirmasi */}
      {isModalOpen && selectedBank && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#2D2D2D] border border-gray-600 rounded-lg p-8 w-11/12 max-w-md shadow-xl transform transition-all duration-300">
            {!isPending ? (
              <>
                <h3 className="text-xl font-semibold mb-4">Konfirmasi Deposit</h3>
                <div className="mb-4">
                  <p>
                    <strong>Jumlah:</strong> Rp {parseFloat(amount).toLocaleString()}
                  </p>
                  <p>
                    <strong>Waktu:</strong> {new Date().toLocaleString()}
                  </p>
                </div>

                {/* Bagian Informasi Bank */}
                <div className="border-t border-gray-500 pt-4 mb-4">
                  <h4 className="text-md font-semibold mb-2">Deposit Ke:</h4>
                  <div className="space-y-1">
                    <p>
                      <strong>Nama Bank:</strong> {selectedBank.NamaBank}
                    </p>
                    <p>
                      <strong>Nomor Bank:</strong> {selectedBank.TujuanBank}
                    </p>
                    <p>
                      <strong>Nama Rekening:</strong> {selectedBank.NamaLengkap}
                    </p>
                  </div>
                </div>

                <p className="mb-6">
                  Silakan transfer jumlah yang ditentukan ke rekening bank di atas.
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={handleCancelDeposit}
                    className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors duration-300"
                  >
                    Batal
                  </button>
                  <button
                    onClick={handleConfirmDeposit}
                    className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors duration-300"
                  >
                    Konfirmasi
                  </button>
                </div>
              </>
            ) : (
              // Status Pending dengan Indikator Loading
              <div className="flex flex-col items-center">
                <svg
                  className="animate-spin h-12 w-12 text-green-600 mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                <p className="text-lg font-semibold">Memproses deposit Anda...</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Komponen Toast */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Deposit;

import React, { useState } from 'react';
import { FaBars, FaBell, FaMoneyBillWave, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

function Header({ toggleSidebar }) {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const userData = useUser(); // Get user data from context

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleNotifications = () => {
    setActiveDropdown(activeDropdown === 'notifications' ? null : 'notifications');
  };

  const toggleProfileMenu = () => {
    setActiveDropdown(activeDropdown === 'profile' ? null : 'profile');
  };

  const handleViewProfile = () => {
    navigate('/settings/profile'); // Navigate to the profile page
  };

  const today = new Date().toLocaleDateString();

  // Helper function to format numbers as Rupiah with commas
  const formatRupiah = (number) => {
    if (typeof number !== 'number') {
      number = Number(number);
      if (isNaN(number)) return '0';
    }
    return new Intl.NumberFormat('id-ID').format(number);
  };

  return (
    <header className="bg-[#FBAE1C] text-white p-4 flex items-center justify-between">
      <button
        className="mr-4 block md:hidden transition-transform duration-200 hover:scale-105"
        onClick={toggleSidebar}
      >
        <FaBars size={24} />
      </button>

{/* Logo container */}
<div className="flex items-center justify-center md:justify-start w-full md:w-auto">
  <img
    src="https://imgdb.net/storage/uploads/e4c7bc083ee74ad37404a65aee1532453fbbfc06f1e046b6944530c2276b1904.png" // Replace with your logo image
    alt="Logo"
    className="h-10 md:h-12 lg:h-14 w-auto object-contain"
  />
</div>


      <div className="flex items-center ml-auto text-right">
        {/* Balance and Status */}
        <div className="hidden md:flex items-center mr-4">
          <FaMoneyBillWave size={20} className="mr-2" />
          <span className="text-black font-bold mr-2">
            Rp {formatRupiah(userData?.balance) /* Display the formatted balance */}
          </span>
          {userData?.status?.toLowerCase() === 'active' ? (
            <div className="bg-green-500 text-white text-xs rounded px-2 py-1">
              Live
            </div>
          ) : (
            <div className="bg-[#3E3E3E] text-white text-xs rounded px-2 py-1">
              Free
            </div>
          )}
        </div>

        {/* Today's Date */}
        <div className="hidden md:block mr-4">
          <span className="block text-black">Today’s Date</span>
          <span className="block text-black">{today}</span>
        </div>

        {/* Notifications Icon */}
        <div className="relative ml-4 cursor-pointer" onClick={toggleNotifications}>
          <FaBell size={24} />
          <span className="absolute top-0 right-0 h-2 w-2 bg-red-600 rounded-full border border-white"></span>

          <div
            className={`absolute right-0 mt-2 w-48 bg-[#252525] text-white border border-gray-700 shadow-lg rounded p-2 text-sm transition-transform duration-300 ${
              activeDropdown === 'notifications'
                ? 'transform translate-y-0 opacity-100'
                : 'transform translate-y-2 opacity-0 pointer-events-none'
            }`}
          >
            <p>You have no notifications!</p>
            <a href="/" className="text-blue-400">
              View all
            </a>
          </div>
        </div>

        {/* Profile Menu */}
        <div className="relative ml-4 cursor-pointer" onClick={toggleProfileMenu}>
          <img
            src={userData?.profile_image || 'https://via.placeholder.com/24'} // Use user's real profile image or a placeholder
            alt="Profile"
            className="rounded-full h-10 w-10 object-cover" // Use object-cover to maintain aspect ratio
          />

          <div
            className={`absolute right-0 mt-2 w-48 bg-[#252525] text-white border border-gray-700 shadow-lg rounded p-4 text-sm transition-transform duration-300 ${
              activeDropdown === 'profile'
                ? 'transform scale-100 translate-y-0 opacity-100'
                : 'transform scale-95 translate-y-2 opacity-0 pointer-events-none'
            }`}
          >
            <div className="flex items-center mb-2">
              <img
                src={userData?.profile_image || 'https://via.placeholder.com/24'} // Use user's real profile image or a placeholder
                alt="Profile"
                className="rounded-full h-12 w-12 mr-2"
              />
              <div>
                <p className="font-bold">{userData?.full_name || 'Full Name'}</p>
                <p className="text-gray-400">@{userData?.username || 'username'}</p>
              </div>
            </div>
            <button
              className="w-full bg-[#FBAE1C] text-black py-1 px-2 rounded mb-2 flex items-center"
            >
              <FaMoneyBillWave size={20} className="mr-2" /> {/* Money icon inside the button */}
              <span>Rp {formatRupiah(userData?.balance)}</span>
            </button>
            <button
              className="w-full bg-[#FBAE1C] text-black py-1 px-2 rounded mb-2"
              onClick={handleViewProfile} // Navigate to the profile page
            >
              View Profile
            </button>
            <hr className="border-gray-600 mb-2" />
            <button className="w-full text-red-600 flex items-center" onClick={handleLogout}>
              <FaSignOutAlt className="mr-2" /> Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

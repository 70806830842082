import React, { useState, useEffect } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight, FaSave } from 'react-icons/fa'; // Import React Icons
import { useUser } from '../UserContext';
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS

const Profile = () => {
  const userData = useUser(); // Get user data from context

  // States for form data
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bankName, setBankName] = useState('');  // New state for Nama Bank
  const [bankAccountNumber, setBankAccountNumber] = useState('');  // New state for Nomor Account Bank

  const [isInitialized, setIsInitialized] = useState(false); // State to control if data is already set

  // List of Indonesian banks
  const banks = [
    "Bank BCA", "Bank Mandiri", "Bank BRI", "Bank BNI", "Bank BTN", 
    "Bank Syariah Indonesia", "Bank Danamon", "Bank CIMB Niaga", 
    "Bank Permata", "Bank OCBC NISP", "Bank Mega", "Bank Sinarmas",
    "Bank Bukopin", "Bank HSBC", "Bank Commonwealth", "Bank UOB Indonesia",
    "OVO", "GoPay", "DANA", "LinkAja", "ShopeePay"
  ];

  // Populate the form with user data once when the component mounts
  useEffect(() => {
    if (userData && !isInitialized) {
      setFullName(userData.full_name || '');
      setAddress(userData.address || '');
      setCountry(userData.country || '');
      setPhoneNumber(userData.phone || '');
      setBankName(userData.bank_name || '');  // Assuming userData includes bank_name
      setBankAccountNumber(userData.bank_number || '');  // Assuming userData includes bank_number
      setIsInitialized(true);  // Mark the form as initialized
    }
  }, [userData, isInitialized]);

  // Handle profile update
  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token'); // Get the latest token from localStorage
    const profileData = {
      full_name: fullName,
      address: address,
      phone: phoneNumber,
      bank_name: bankName,  // Include bank name in profile data
      bank_number: bankAccountNumber,  // Include bank account number in profile data
      token: token // Send the token in the request payload
    };
    const origin = window.location.origin; // Get the origin domain dynamically    

    try {
      const response = await fetch(`${origin}/api/update_profile.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(profileData)
      });

      const result = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully!', { position: "top-right" });
      } else {
        toast.error(result.message || 'Failed to update profile.', { position: "top-right" });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An error occurred while updating the profile.', { position: "top-right" });
    }
  };

  // Handle password change
  const handleChangePassword = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token'); // Get the latest token from localStorage
    const passwordData = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
      token: token // Send the token in the request payload
    };

    try {
      const response = await fetch(`${origin}/api/change_password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(passwordData)
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message || 'Password Berhasil Diubah!', { position: "top-right" });
        localStorage.removeItem('token'); // Remove the token to log out
        setTimeout(() => {
          window.location.href = '/login'; // Redirect to login page after success
        }, 2000); // Delay to show the toast
      } else {
        toast.error(result.message || 'Password Gagal Diubah.', { position: "top-right" });
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('An error occurred while updating the password.', { position: "top-right" });
    }
  };

  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Profile</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Settings</span>
            <FaChevronRight className="mx-2" />
            <span>Profile</span>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-grow">
          <Ticker />

          {/* Form Container */}
          <div className="flex flex-col md:flex-row gap-4 mt-4">

            {/* User Information Form */}
            <div className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg md:w-3/4">
              {/* Form */}
              <h2 className="text-lg font-semibold mb-4">User Information</h2>
              <form className="space-y-4" onSubmit={handleProfileUpdate}>
                <div>
                  <label className="block text-sm font-medium mb-1">Username</label>
                  <input 
                    type="text" 
                    value={userData?.username || "Error"} 
                    readOnly
                    className="bg-[#111111] border border-gray-600 text-white p-2 rounded w-full cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Nama Lengkap</label>
                  <input 
                    type="text" 
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Alamat</label>
                  <input 
                    type="text" 
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Negara</label>
                  <input 
                    type="text" 
                    value={country}
                    readOnly
                    className="bg-[#111111] border border-gray-600 text-white p-2 rounded w-full cursor-not-allowed"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Nomor Telepon</label>
                  <input 
                    type="tel" 
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Email Address</label>
                  <input 
                    type="email" 
                    value={userData?.email || "Error"} 
                    readOnly
                    className="bg-[#111111] border border-gray-600 text-white p-2 rounded w-full cursor-not-allowed"
                  />
                </div>

                {/* Edit Bank Information Section */}
                <div>
                  <label className="block text-sm font-medium mb-1">Nama Bank</label>
                  <select
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  >
                    <option value="">Pilih Bank</option>
                    {banks.map((bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Nomor Akun Bank</label>
                  <input 
                    type="text" 
                    value={bankAccountNumber}
                    onChange={(e) => setBankAccountNumber(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <button 
                  type="submit"
                  className="bg-[#FFD700] text-black mt-4 flex items-center justify-center py-2 px-4 rounded hover:bg-[#FFC107] transition"
                >
                  <FaSave className="mr-2" />
                  Update
                </button>
              </form>
            </div>

            {/* Change Password Form */}
            <div className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg md:w-1/4 h-auto">
              {/* Form */}
              <h2 className="text-lg font-semibold mb-4">Ganti Kata Sandi</h2>
              <form className="space-y-4" onSubmit={handleChangePassword}>
                <div>
                  <label className="block text-sm font-medium mb-1">Sandi Sekarang</label>
                  <input 
                    type="password" 
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Sandi Baru</label>
                  <input 
                    type="password" 
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Ketik Ulang Sandi Baru</label>
                  <input 
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full focus:bg-[#111111] focus:outline-none"
                  />
                </div>
                <button 
                  type="submit"
                  className="bg-[#FFD700] text-black mt-4 flex items-center justify-center py-2 px-4 rounded hover:bg-[#FFC107] transition"
                >
                  <FaSave className="mr-2" />
                  Update Password
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Toast Container for Notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Profile;

import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import bgImage from '../assets/bg.jpg'; // Adjust the path to where your bg.jpg is located

const GridContainer = () => {
  const items = [
    {
      title: 'Silver',
      price: 'Rp 500,000',
      profit: '0.5% Daily [300 Day]',
      exp: '05-Jun-2025',
      details: 'Details',
    },
    {
      title: 'Gold',
      price: 'Rp 750,000',
      profit: '0.7% Daily [365 Day]',
      exp: '20-Dec-2025',
      details: 'Details',
    },
    {
      title: 'Platinum',
      price: 'Rp 1,000,000',
      profit: '1.0% Daily [365 Day]',
      exp: '15-Aug-2025',
      details: 'Details',
    },
    {
      title: 'Diamond',
      price: 'Rp 1,500,000',
      profit: '1.5% Daily [365 Day]',
      exp: '30-Nov-2025',
      details: 'Details',
    },
    {
      title: 'Ultimate',
      price: 'Rp 2,000,000',
      profit: '2.0% Daily [365 Day]',
      exp: '31-Dec-2025',
      details: 'Details',
    },
    {
      title: 'Free Invest',
      price: 'Free',
      profit: '0.2% Daily [100 Day]',
      exp: '01-Jan-2025',
      details: 'Details',
    },
  ];

  // Define colors for each rank
  const iconColors = {
    Silver: '#FFD700', // Silver color
    Gold: '#FFD700',   // Gold color
    Platinum: '#FFD700', // Platinum color
    Diamond: '#FFD700', // Diamond color
    Ultimate: '#FFD700', // Ultimate color
    'Free Invest': '#FFD700', // Grey color for Free Invest
  };

  // Define color for profit text
  const profitColor = '#00FF00'; // Green color

  return (
    <div className="mt-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {items.map((item, index) => (
          <div
            key={index}
            className="bg-[#2F2F2F] p-4 rounded-lg border border-gray-600 flex flex-col justify-between transition-transform transform hover:scale-105 hover:shadow-lg"
            style={{
              backgroundImage: `url(${bgImage}), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`,
              backgroundSize: 'cover',
              backgroundBlendMode: 'overlay',
              backgroundPosition: 'center',
            }}
          >
            <div className="flex items-start mb-4">
              <Icon
                path={mdiCheckboxMarkedCircleOutline}
                size={2} // Reduced size
                color={iconColors[item.title]} // Apply color based on rank
                className="mr-4"
              />
              <div className="flex flex-col justify-between">
                <h4 className="text-xl font-bold text-white mb-1">{item.title}</h4>
                <p className="text-gray-300 text-lg mb-1">{item.price}</p>
                <p className="text-sm" style={{ color: profitColor }}>{item.profit}</p>
              </div>
            </div>
            <div className="flex justify-between items-center mt-auto">
              <p className="text-gray-300">{item.exp}</p>
              <button className="bg-gold-500 text-white font-bold py-2 px-4 rounded hover:bg-gold-600 focus:outline-none focus:ring-2 focus:ring-gold-400">
                {item.details}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridContainer;

// orderService.js

// Retrieve the token from localStorage or UserContext
const getToken = () => localStorage.getItem('token');

export const sendOrderToBackend = async (orderData) => {
  try {
    const token = getToken();

    const response = await fetch(`${origin}/api/open_order.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add JWT token
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error('Failed to send order data to the backend');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending order data to backend:', error);
    throw error;
  }
};

export const fetchActiveOrder = async () => {
  try {
    const token = getToken();
    const origin = window.location.origin; // Get the origin domain dynamically    
    const response = await fetch(`${origin}/api/CheckOrder.php`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add JWT token
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch active orders from the backend');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching active orders:', error);
    throw error;
  }
};

export const updateOrderInBackend = async (orderData) => {
  try {
    const token = getToken();
    const origin = window.location.origin; // Get the origin domain dynamically    
    const response = await fetch(`${origin}/api/update_order.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add JWT token
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error('Failed to update order data in the backend');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating order data in backend:', error);
    throw error;
  }
};

export const getBalanceFromBackend = async () => {
  try {
    const token = getToken();
    const origin = window.location.origin; // Get the origin domain dynamically    
    const response = await fetch(`${origin}/api/get_balance.php`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add JWT token
      },
    });

    if (!response.ok) throw new Error('Network response was not ok.');
    return await response.json();
  } catch (error) {
    console.error('Error fetching balance:', error);
    throw error;
  }
};

import React, { useState } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight, FaUpload } from 'react-icons/fa'; // Import React Icons
import { useUser } from '../UserContext';
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS

const ProfileImage = () => {
  const userData = useUser(); // Get user data from context
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('No file chosen');

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFileName('No file chosen');
      setFile(null);
    }
  };

  // Handle image upload
  const handleUpload = async () => {
    if (!file) {
      toast.error('No file selected', { position: "top-right" });
      return;
    }

    const token = localStorage.getItem('token'); // Get the latest token from localStorage
    if (!token) {
      toast.error('User not authenticated', { position: "top-right" });
      return;
    }

    const formData = new FormData();
    formData.append('profile_image', file); // Append the image file
    formData.append('token', token); // Pass the token inside the form data

    console.log('Token being sent:', token); // Log the token to console
    const origin = window.location.origin; // Get the origin domain dynamically    
      
    try {
      const response = await fetch(`${origin}/api/update_image.php`, {
        method: 'POST',
        body: formData, // Send the formData including token and file
      });

      const result = await response.json();
      if (response.ok && result.success) {
        toast.success('Image uploaded successfully!', { position: "top-right" });
        // Optionally, refresh user data or update the user context here
      } else {
        toast.error(result.message || 'Failed to upload image.', { position: "top-right" });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('An error occurred while uploading the image.', { position: "top-right" });
    }
  };

  return (
    <div className="bg-[#2F2F2F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Profile Image</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Settings</span>
            <FaChevronRight className="mx-2" />
            <span>Profile Image</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow">
          <Ticker />
          <div className="bg-[#1F1F1F] border border-gray-600 p-4 rounded-lg">
            <div className="text-lg font-semibold mb-4">Foto Profile</div>

            {/* Description */}
            <p className="mb-4">
              Halaman ini memungkinkan Anda untuk meng-upload foto pribadi Anda untuk profile Anda.
            </p>
            <p className="mb-4">
              <strong>Ketentuan Foto</strong>
              <br />
              Format foto yang diijinkan hanya: jpg, jpeg, png, dan gif.
              <br />
              Ukuran foto akan di-resize menjadi maksimal lebar 1024 piksel.
              <br />
              Ukuran file foto tidak boleh lebih dari 1 MB.
            </p>

            {/* Image Placeholder and File Input */}
            <div className="mb-4">
              <img 
                src={userData?.profile_image || "https://via.placeholder.com/150"} 
                alt="Profile" 
                className="w-36 h-36 rounded-full mb-4 border border-gray-600"
              />
              <label htmlFor="file-upload" className="block text-sm font-medium mb-2">Select image</label>
              <input 
                type="file" 
                id="file-upload" 
                accept=".jpg, .jpeg, .png, .gif" 
                className="bg-[#252525] border border-gray-600 text-white p-2 rounded w-full"
                onChange={handleFileChange}
              />
              <p className="text-sm text-gray-400 mt-2">{fileName}</p>
            </div>
            <p className="text-sm text-gray-400 mb-4">
              Upload only file jpg, png, gif. Max size upload 1 MB.
            </p>

            {/* Update Button */}
            <button 
              type="button" 
              className="bg-[#FFD700] text-black py-2 px-4 rounded flex items-center mt-4"
              onClick={handleUpload}
            >
              <FaUpload className="mr-2" />
              <span>Update</span>
            </button>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default ProfileImage;

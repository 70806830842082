// src/utils/tradeUtils.js

// Map trading pairs to CoinGecko ids
export const formatCoinGeckoSymbol = (pair) => {
  const symbols = {
    'BTC/USD': 'bitcoin',
    'ETH/USD': 'ethereum',
    'LTC/USD': 'litecoin',
    'XRP/USD': 'ripple',
    'ADA/USD': 'cardano',
    'SOL/USD': 'solana',
    'DOGE/USD': 'dogecoin',
    'DOT/USD': 'polkadot',
    'SHIB/USD': 'shiba-inu',
    'TRX/USD': 'tron',
    'LINK/USD': 'chainlink',
    'MATIC/USD': 'polygon',
    // Add any additional pairs here
  };

  console.log(`Formatting CoinGecko symbol: ${pair}`); // Debug log for the pair input
  const formattedSymbol = symbols[pair];
  if (!formattedSymbol) {
    throw new Error(`Unsupported trading pair for CoinGecko: ${pair}`);
  }
  console.log(`Formatted CoinGecko symbol: ${formattedSymbol}`); // Log the formatted symbol

  return formattedSymbol;
};

// Map trading pairs to TradingView symbols with Bitstamp exchange
export const formatTradingViewSymbol = (pair) => {
  const symbols = {
    'BTC/USD': 'BITSTAMP:BTCUSD',
    'ETH/USD': 'BITSTAMP:ETHUSD',
    'LTC/USD': 'BITSTAMP:LTCUSD',
    'XRP/USD': 'BITSTAMP:XRPUSD',
    'ADA/USD': 'BITSTAMP:ADAUSD',
    'SOL/USD': 'BITSTAMP:SOLUSD',
    'DOGE/USD': 'BITSTAMP:DOGEUSD',
    'DOT/USD': 'BITSTAMP:DOTUSD',
    'SHIB/USD': 'BITSTAMP:SHIBUSD',
    'TRX/USD': 'BITSTAMP:TRXUSD',
    'LINK/USD': 'BITSTAMP:LINKUSD',
    'MATIC/USD': 'BITSTAMP:MATICUSD',
    // Add any additional pairs here
  };

  console.log(`Formatting TradingView symbol: ${pair}`); // Debug log for the pair input
  const formattedSymbol = symbols[pair];
  if (!formattedSymbol) {
    throw new Error(`Unsupported trading pair for TradingView: ${pair}`);
  }
  console.log(`Formatted TradingView symbol: ${formattedSymbol}`); // Log the formatted symbol

  return formattedSymbol;
};

// Convert interval string to milliseconds
export const intervalToMilliseconds = (intervalValue) => {
  const intervals = {
    '30 seconds': 30 * 1000,
    '1 minute': 60 * 1000,
    '2 minutes': 2 * 60 * 1000,
    '5 minutes': 5 * 60 * 1000,
    '15 minutes': 15 * 60 * 1000,
    '30 minutes': 30 * 60 * 1000,
    '1 hour': 60 * 60 * 1000,
    '2 hours': 2 * 60 * 60 * 1000,
    '4 hours': 4 * 60 * 60 * 1000,
    '1 day': 24 * 60 * 60 * 1000,
    '1 week': 7 * 24 * 60 * 60 * 1000,
    '1 month': 30 * 24 * 60 * 60 * 1000, // Approximate value for a month
  };
  return intervals[intervalValue] || 60 * 1000; // Default to 1 minute
};

// Fetch the current price from CoinGecko
export const getCurrentPrice = async (coinId) => {
  if (!coinId) {
    throw new Error(`Invalid CoinGecko symbol: ${coinId}`);
  }

  try {
    console.log(`Fetching price for coin: ${coinId}`); // Debug log for the coin ID being fetched

    // Fetch price data from CoinGecko API
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`
    );
    const data = await response.json();

    console.log('CoinGecko API response:', data); // Log the API response

    // Ensure we have a valid response from the API
    if (!response.ok || !data[coinId] || !data[coinId].usd) {
      throw new Error(`Failed to fetch price for ${coinId} from CoinGecko`);
    }

    const price = parseFloat(data[coinId].usd);
    console.log(`Fetched price for ${coinId}: $${price}`); // Log the fetched price

    // Return the price in USD
    return price;
  } catch (error) {
    console.error('Error fetching current price from CoinGecko:', error);
    throw error;
  }
};

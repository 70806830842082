import React from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight } from 'react-icons/fa'; // Import React Icons
import { useUser } from '../UserContext';

const Referral = () => {
  const userData = useUser(); // Get user data from context
  const data = userData?.Referral || []; // Get referrals from user data

  return (
    <div className="bg-[#1F1F1F] h-full w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Referral</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Referral</span>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-grow flex flex-col">
          <Ticker />

          {/* Total Referrals */}
          <div className="text-xl font-bold mb-4">
            Total Referrals: {userData?.referral || "0"}
          </div>

         
         
        </div>
      </div>
    </div>
  );
};

export default Referral;

import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for HTTP requests
import Ticker from '../components/Ticker'; // Adjust path if necessary
import { FaHome, FaChevronRight, FaExclamationTriangle } from 'react-icons/fa'; // Import additional icons
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast for notifications

const KYC = () => {
  const navigate = useNavigate();

  // State to manage the KYC form
  const [name, setName] = useState('');
  const [ktpFile, setKtpFile] = useState(null);
  const [selfieFile, setSelfieFile] = useState(null);

  // State to store KYC history
  const [kycHistory, setKycHistory] = useState([]);

  // State to manage submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  
  // State to check if KYC is allowed
  const [kycAllowed, setKycAllowed] = useState(true);

  // Fetch KYC history and check user status when the component loads
  useEffect(() => {
    checkUserStatus(); // Check if user is active
    fetchKYCHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to check the user status
  const checkUserStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error('Token otentikasi tidak ditemukan. Silakan login kembali.');
        navigate('/login');
        return;
      }

      const origin = window.location.origin; // Get the origin dynamically
      const response = await axios.get(`${origin}/api/get-user-status.php`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const userStatus = response.data.data.status.toLowerCase();

        // Redirect if user is active
        if (userStatus === 'active') {
          navigate('/');
        }
      } else if (response.data.message === "Akun Anda belum aktif untuk melakukan KYC") {
        setKycAllowed(false); // KYC is not allowed for this account
        setError(response.data.message); // Display the error message to the user
      }
    } catch (error) {
      console.error('Error checking user status:', error);
      toast.error('Terjadi kesalahan saat memeriksa status pengguna.');
    }
  };

  const fetchKYCHistory = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        toast.error('Token otentikasi tidak ditemukan. Silakan login kembali.');
        navigate('/login');
        return;
      }
      const origin = window.location.origin; // Get the origin domain dynamically

      const response = await axios.get(`${origin}/api/get-kyc-history.php`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setKycHistory(response.data.data);
      } else {
        toast.error(response.data.message || 'Gagal mengambil riwayat KYC.');
      }
    } catch (err) {
      console.error('Error fetching KYC history:', err);
      toast.error('Terjadi kesalahan saat mengambil riwayat KYC.');
    }
  };

  // Handler for KYC form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    // Validate the form before submission
    if (!name || !ktpFile || !selfieFile) {
      setError('Semua bidang harus diisi.');
      setIsSubmitting(false);
      return;
    }

    // Validate file size (e.g., max 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (ktpFile.size > maxSize || selfieFile.size > maxSize) {
      setError('Ukuran file tidak boleh lebih dari 5MB.');
      setIsSubmitting(false);
      return;
    }

    // Validate file type
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!allowedTypes.includes(ktpFile.type) || !allowedTypes.includes(selfieFile.type)) {
      setError('Hanya file JPG, JPEG, dan PNG yang diizinkan.');
      setIsSubmitting(false);
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append('name', name);
    formData.append('ktp', ktpFile);
    formData.append('selfie', selfieFile);
    formData.append('date', new Date().toISOString().split('T')[0]); // Format YYYY-MM-DD

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        toast.error('Token otentikasi tidak ditemukan. Silakan login kembali.');
        navigate('/login');
        setIsSubmitting(false);
        return;
      }

      const response = await axios.post(`${origin}/api/kyc.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        toast.success('KYC berhasil dikirim dan sedang diproses.');
        // Reset form
        setName('');
        setKtpFile(null);
        setSelfieFile(null);
        // Add new KYC record to the state
        setKycHistory([
          ...kycHistory,
          {
            id: kycHistory.length + 1, // You might need to get the actual ID from backend
            date: new Date().toISOString().split('T')[0],
            status: 'Diproses',
          },
        ]);
      } else {
        setError(response.data.message || 'KYC gagal dikirim.');
        toast.error(response.data.message || 'KYC gagal dikirim.');
      }
    } catch (err) {
      console.error('Error sending KYC:', err);
      setError('Terjadi kesalahan saat mengirim KYC. Silakan coba lagi.');
      toast.error('Terjadi kesalahan saat mengirim KYC. Silakan coba lagi.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#2F2F2F] min-h-screen w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">KYC</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Pengaturan</span>
            <FaChevronRight className="mx-2" />
            <span>KYC</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow">
          <Ticker />
          
          {!kycAllowed ? (
            // Display a warning if KYC is not allowed
            <div className="bg-red-600 bg-opacity-20 border border-red-500 rounded-lg p-4 mb-6 flex items-start">
              <FaExclamationTriangle className="text-red-500 text-2xl mr-4 mt-1" />
              <div>
                <h2 className="text-lg font-semibold mb-2">Akun Anda Belum Aktif untuk KYC</h2>
                <p>{error}</p>
              </div>
            </div>
          ) : (
            <>
              {/* Pengumuman KYC */}
              <div className="bg-red-600 bg-opacity-20 border border-red-500 rounded-lg p-4 mb-6 flex items-start">
                <FaExclamationTriangle className="text-red-500 text-2xl mr-4 mt-1" />
                <div>
                  <h2 className="text-lg font-semibold mb-2">Penting: Selesaikan KYC Anda</h2>
                  <p>Untuk mengakses fitur-fitur lengkap, Anda perlu menyelesaikan proses KYC. Silakan lengkapi formulir di bawah ini.</p>
                </div>
              </div>
              
              {/* Formulir KYC */}
              <form onSubmit={handleSubmit} className="bg-[#3E3E3E] p-6 rounded-lg shadow-md mb-6">
                <h2 className="text-xl font-bold mb-4">Formulir KYC</h2>
                
                {/* Nama Lengkap */}
                <div className="mb-4">
                  <label htmlFor="name" className="block mb-2">Nama Lengkap</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full p-2 rounded bg-[#2F2F2F] border border-gray-500 focus:outline-none focus:border-[#FBAE1C]"
                    placeholder="Masukkan nama lengkap Anda"
                  />
                </div>
                
                {/* Unggah KTP */}
                <div className="mb-4">
                  <label htmlFor="ktp" className="block mb-2">Unggah KTP</label>
                  <input
                    type="file"
                    id="ktp"
                    name="ktp"
                    accept=".jpg,.jpeg,.png"
                    required
                    onChange={(e) => setKtpFile(e.target.files[0])}
                    className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-[#FBAE1C] file:text-black hover:file:bg-[#D4AF37]"
                  />
                </div>
                
                {/* Unggah Selfie */}
                <div className="mb-4">
                  <label htmlFor="selfie" className="block mb-2">Unggah Selfie</label>
                  <input
                    type="file"
                    id="selfie"
                    name="selfie"
                    accept=".jpg,.jpeg,.png"
                    required
                    onChange={(e) => setSelfieFile(e.target.files[0])}
                    className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-[#FBAE1C] file:text-black hover:file:bg-[#D4AF37]"
                  />
                </div>
                
                {/* Tombol Submit */}
                <button
                  type="submit"
                  className={`w-full bg-[#FBAE1C] text-black font-bold py-2 px-4 rounded hover:bg-[#D4AF37] transition-colors duration-200 ${
                    isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Mengirim...' : 'Kirim KYC'}
                </button>

                {/* Pesan Kesalahan */}
                {error && (
                  <p className="mt-4 text-red-500 text-sm">
                    {error}
                  </p>
                )}
              </form>
            </>
          )}
          
          {/* Riwayat KYC */}
          <div className="bg-[#3E3E3E] p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Riwayat KYC</h2>
            {kycHistory.length > 0 ? (
              <ul>
                {kycHistory.map((kyc) => (
                  <li key={kyc.Id || kyc.id} className="flex justify-between items-center mb-2">
                    <span>{kyc.date}</span>
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      kyc.status === 'Disetujui' ? 'bg-green-500' :
                      kyc.status === 'Diproses' ? 'bg-yellow-500' :
                      'bg-red-500'
                    }`}>
                      {kyc.status}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Tidak ada riwayat KYC.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYC;

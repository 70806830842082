// components/TradeModal.js
import React from 'react';

const TradeModal = ({ orderType, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#1F1F1F] p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4">
          Confirm {orderType}
        </h2>
        <p>Are you sure you want to {orderType.toLowerCase()}?</p>
        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-700 mr-2"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradeModal;

// Withdrawal.js

import React, { useState, useEffect } from 'react';
import Ticker from '../components/Ticker'; // Adjust the path as necessary
import { FaHome, FaChevronRight, FaArrowDown } from 'react-icons/fa'; // Import React Icons
import { useUser } from '../UserContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // 1. Import useNavigate
const origin = window.location.origin; // Get the origin domain dynamically    
const WITHDRAW_API_URL = `${origin}/api/request_withdraw.php`; // Replace with your actual URL


const Withdrawal = () => {
  const [amount, setAmount] = useState('');
  const [catatan, setCatatan] = useState('');
  const [bankID, setBankID] = useState('');
  const [bankDetails, setBankDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const userData = useUser(); // Get user data from context
  const navigate = useNavigate(); // 2. Initialize navigate

  // Fetch bank details and pending withdrawals on component mount
  useEffect(() => {
    const fetchBankDetails = async () => {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      if (!token) {
        toast.error('Authentication token not found.');
        return;
      }

      try {
        const response = await fetch(WITHDRAW_API_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const result = await response.json();

        if (response.ok && result.success) {
          setBankDetails(result.data.bankDetails);
          setPendingWithdrawals(result.data.pendingWithdrawals);
        } else {
          toast.error(result.message || 'Failed to fetch bank details.');
        }
      } catch (error) {
        console.error('Error fetching bank details:', error);
        toast.error('An error occurred while fetching bank details.');
      }
    };

    fetchBankDetails();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCatatanChange = (e) => {
    setCatatan(e.target.value);
  };

  const handleBankIDChange = (e) => {
    setBankID(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input Validation
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount)) {
      toast.error('Please enter a valid number.');
      return;
    }

    if (numericAmount < 50000) {
      toast.error('Minimum withdrawal amount is Rp 50,000.');
      return;
    }

    if (numericAmount > 1000000000) {
      toast.error('Maximum withdrawal amount is Rp 1,000,000,000.');
      return;
    }

    if (!bankID) {
      toast.error('Please select a bank account.');
      return;
    }

    // Check if there are pending withdrawals
    if (pendingWithdrawals.length > 0) {
      toast.error('You have a pending withdrawal request. Please wait until it is processed before making a new request.');
      return;
    }

    setLoading(true);

    const token = localStorage.getItem('token'); // Retrieve JWT token

    if (!token) {
      toast.error('Authentication token not found. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(WITHDRAW_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          Amount: numericAmount,
          catatan: catatan,
          BankID: parseInt(bankID, 10),
        }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        toast.success(result.message || 'Withdrawal request submitted successfully.');
        setAmount('');
        // 3. Add Navigation After Successful Withdrawal
        setTimeout(() => {
          navigate('/HistoryBalance'); // Redirect to /HistoryBalance after 2 seconds
        }, 2000);
        setCatatan('');
        setBankID('');
        // Optionally, refresh bank details and pending withdrawals
        const updatedResponse = await fetch(WITHDRAW_API_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const updatedResult = await updatedResponse.json();

        if (updatedResponse.ok && updatedResult.success) {
          setBankDetails(updatedResult.data.bankDetails);
          setPendingWithdrawals(updatedResult.data.pendingWithdrawals);
        }
      } else {
        toast.error(result.message || 'Withdrawal request failed.');
      }
    } catch (error) {
      console.error('Error during withdrawal:', error);
      toast.error('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#1F1F1F] min-h-screen w-full text-white p-5 box-border">
      <div className="flex flex-col h-full">
        {/* Header section */}
        <div className="flex items-center justify-between mb-4 border-b border-gray-600 pb-2">
          <h1 className="text-xl font-bold">Withdrawal</h1>
          <div className="flex items-center text-sm text-gray-400">
            <FaHome className="mr-2" />
            <FaChevronRight className="mx-2" />
            <span>Balance</span>
            <FaChevronRight className="mx-2" />
            <span>Withdrawal</span>
          </div>
        </div>
        
        {/* Main content */}
        <div className="flex-grow flex flex-col">
          <Ticker />

          {/* Available Balance Section */}
          <div className="bg-[#2D2D2D] border border-gray-600 rounded-lg p-4 mt-4 mb-4">
            <h2 className="text-lg font-bold mb-4">Available Balance</h2>
            <div className="flex items-center text-lg font-bold">
              <span>Rp</span>
              <span className="ml-2">{userData?.balance ? userData.balance.toLocaleString() : "0"}</span>
            </div>
          </div>

          {/* Withdrawal Section */}
          <div className="bg-[#2D2D2D] border border-gray-600 rounded-lg p-4 mt-4">
            <h2 className="text-lg font-bold mb-4">Amount Withdrawal</h2>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
              {/* Amount Withdrawal Label and Input */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="amount" className="text-sm text-gray-400">Amount Withdrawal</label>
                <div className="flex items-center bg-gray-700 rounded">
                  <span className="text-gray-400 p-2">Rp</span>
                  <input
                    id="amount"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="50,000"
                    className="flex-1 p-2 bg-gray-700 text-white rounded-r outline-none"
                    min="50000" // Minimum amount
                    max="1000000000" // Maximum amount
                    step="1000" // Step for valid values
                    required
                  />
                </div>
              </div>

              {/* Catatan (Notes) Input */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="catatan" className="text-sm text-gray-400">Notes</label>
                <textarea
                  id="catatan"
                  value={catatan}
                  onChange={(e) => setCatatan(e.target.value)}
                  placeholder="Enter your notes here..."
                  className="p-2 bg-gray-700 text-white rounded outline-none resize-none"
                  rows="3"
                  required
                ></textarea>
              </div>

              {/* Bank Selection */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="bank" className="text-sm text-gray-400">Select Bank Account</label>
                <select
                  id="bank"
                  value={bankID}
                  onChange={handleBankIDChange}
                  className="p-2 bg-gray-700 text-white rounded outline-none"
                  required
                >
                  <option value="">-- Select Bank Account --</option>
                  {bankDetails.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.bank_name} - {bank.bank_number} ({bank.full_name})
                    </option>
                  ))}
                </select>
              </div>

              {/* Notice Section */}
              <div className="text-sm text-gray-400">
                <p>Notice:</p>
                <ul className="list-disc list-inside ml-4">
                  <li>Min Rp 50,000</li>
                  <li>Max Rp 1,000,000,000</li>
                  <li>Fee 0%</li>
                </ul>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className={`w-40 bg-red-600 text-white px-4 py-2 rounded flex items-center space-x-2 mt-4 ${
                  loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'
                }`}
                disabled={loading}
              >
                <FaArrowDown />
                <span>{loading ? 'Processing...' : 'Withdraw'}</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Toast Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Withdrawal;

// Ticker.js
import React, { useEffect } from 'react';

const Ticker = () => {
  useEffect(() => {
    // Check if the ticker tape script is already present
    if (!document.getElementById('tradingview-ticker-tape-script')) {
      const script = document.createElement('script');
      script.id = 'tradingview-ticker-tape-script'; // Add an ID to the script tag for future checks
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        "symbols": [
          { "proName": "FOREXCOM:SPXUSD", "title": "S&P 500 Index" },
          { "proName": "FOREXCOM:NSXUSD", "title": "US 100 CFD" },
          { "proName": "FX_IDC:EURUSD", "title": "EUR/USD" },
          { "proName": "BITSTAMP:BTCUSD", "title": "Bitcoin" },
          { "proName": "BITSTAMP:ETHUSD", "title": "Ethereum" }
        ],
        "showSymbolLogo": true, // Show icons
        "isTransparent": false,
        "displayMode": "adaptive", // Ensure it adjusts to fit content in a single line
        "colorTheme": "dark", // Dark theme
        "width": "100%",
        "height": "60", // Adjust height to fit the design
        "locale": "en"
      });
      document.getElementById('ticker-tape').appendChild(script);
    }
  }, []);

  return (
    <div className="bg-[#1F1F1F] p-4 rounded-lg shadow-md mb-6">
      <div id="ticker-tape" className="w-full h-auto overflow-x-auto mb-4"></div>
    </div>
  );
};

export default Ticker;

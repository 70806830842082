// src/components/DetailsContainer.js
import React from 'react';
import { FaUser, FaDollarSign, FaChartLine, FaWallet } from 'react-icons/fa';
import WaveChart from './WaveChart'; // Import the WaveChart component
import { useUser } from '../UserContext';

const DetailsContainer = () => {
  const userData = useUser(); // Get user data from context

  const details = [
    {
      title: 'Referral',
      content: `Total Referrals\n${userData?.referral || "0"}`, // Use backticks for template literals
      icon: <FaUser className="text-yellow-500 text-3xl" />,
      chartData: [5, 4, 6, 7, 5, 8, 9], // Data for the Referral chart
      borderColor: '#F1C40F',
      backgroundColor: 'rgba(241, 196, 15, 0.2)',
    },
    {
      title: 'Bonus',
      content: `Total Bonus\nRp ${userData?.bonus || "0"}`, // Use backticks for template literals
      icon: <FaDollarSign className="text-yellow-500 text-3xl" />,
      chartData: [3, 2, 4, 6, 3, 5, 7], // Data for the Bonus chart
      borderColor: '#E74C3C',
      backgroundColor: 'rgba(231, 76, 60, 0.2)',
    },
    {
      title: 'Profits',
      content: `Total Profit\nRp ${userData?.profit || "0"}`, // Use backticks for template literals
      icon: <FaChartLine className="text-yellow-500 text-3xl" />,
      chartData: [4, 3, 5, 8, 6, 7, 10], // Data for the Profits chart
      borderColor: '#3498DB',
      backgroundColor: 'rgba(52, 152, 219, 0.2)',
    },
    {
      title: 'Wallet',
      content: `Your Balance\nRp ${userData?.balance || "0"}`, // Use backticks for template literals
      icon: <FaWallet className="text-yellow-500 text-3xl" />,
      chartData: [2, 3, 6, 5, 7, 6, 4], // Data for the Wallet chart
      borderColor: '#2ECC71',
      backgroundColor: 'rgba(46, 204, 113, 0.2)',
    },
  ];

  return (
    <div className="mt-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {details.map((item, index) => (
          <div
            key={index}
            className="bg-[#1F1F1F] p-4 rounded-lg border border-gray-600 flex flex-col transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
          >
            <div className="flex items-start mb-4">
              <div className="flex-shrink-0">
                {item.icon}
              </div>
              <div className="flex-grow flex flex-col ml-4">
                <h4 className="text-xl font-bold text-white mb-1">{item.title}</h4>
                <p className="text-gray-300">{item.content.split('\n')[0]}</p>
                <p className="text-lg font-bold text-yellow-500">{item.content.split('\n')[1]}</p>
              </div>
            </div>
            <div className="mt-4 h-24 rounded-lg">
              <WaveChart 
                data={item.chartData} 
                label={item.title} 
                borderColor={item.borderColor} 
                backgroundColor={item.backgroundColor} 
              /> {/* Render the wave chart */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsContainer;

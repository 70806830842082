import React from 'react';
import { useUser } from '../UserContext';


const Welcome = () => {
  // Define the origin variable
  const origin = window.location.origin;
  const userData = useUser(); // Get user data from context


  return (
    <div className="bg-[#1f1f1f] text-white p-6 font-sans mt-8 border border-gray-600 rounded-lg">
      <h1 className="text-3xl font-bold mb-6">Welcome</h1>
      <hr className="my-6 border-gray-600" />
      <p className="text-lg mb-6">Welcome to Member Panel.</p>
      <p className="text-lg">
        Your Referral Link:
        <a 
          href={`${origin}/register?reff=member`} 
          className="text-lightblue-400 hover:underline ml-2"
        >
          {`${origin}/register?reff=${userData?.username || "username"}`}
        </a>
      </p>
    </div>
  );
}

export default Welcome;
